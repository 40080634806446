import bg from './assets/BG.png';
import bg2 from './assets/frameBg2.png';
import bg3 from './assets/bg3.jpg';
import map1 from './assets/map.jpg';
import map2 from './assets/map2.jpg';
import map3 from './assets/map3.jpg';
import rectFrame1 from './assets/rect-frame1.png';
import rectFrame2 from './assets/rect-frame2.png';
import rectFrame3 from './assets/rect-frame3.png';
import persona1 from './assets/persona.png';
import persona2 from './assets/persona2.png';
import persona3 from './assets/persona3.png';
import innerFrame1 from './assets/rectangle.png';
import minimap from './assets/mini-map.png';
import minimap2 from './assets/mini-map-2.png';
import minimap3 from './assets/mini-map-3.jpg';
import ava from './assets/Ava.png';
import ava2 from './assets/Ava2.png';
import ava3 from './assets/ava3.png';
import protonMini from './assets/proton_mini.png';
import colliderMini from './assets/collider_mini.png';
import crystalMini1 from './assets/crystal-mini-1.png';
import crystalMini2 from './assets/crystal-mini-2.png';
import coinMini from './assets/coin_mini.png';
import caseMini from './assets/case_mini.png';
import rocketMini from './assets/Rocket.png';
import collider from './assets/collider.png';
import caser from './assets/case.png';
import btnCrystal from './assets/btn-crystal.png';
import arcadeBtn2 from './assets/arcadeBtn2.png';
import arcadeBtn3 from './assets/arcadeBtn3.png';
import cardAstronomy1Front from './assets/card-astronomy1-front.png';
import cardAstronomy1Back from './assets/card-astronomy1-back.png';
import cardAstronomy1Success from './assets/card-astronomy1-success.png';
import cardAstronomy1Fail from './assets/card-astronomy1-fail.png';
import cardPhysics1Front from './assets/card-physics1-front.png';
import cardPhysics1Back from './assets/card-physics1-back.png';
import cardPhysics1Success from './assets/card-physics1-success.png';
import cardPhysics1Fail from './assets/card-physics1-fail.png';
import cardChemistry1Front from './assets/card-chemistry1-front.png';
import cardChemistry1Back from './assets/card-chemistry1-back.png';
import cardChemistry1Success from './assets/card-chemistry1-success.png';
import cardChemistry1Fail from './assets/card-chemistry1-fail.png';
import cardHistory1Front from './assets/card-history1-front.png';
import cardHistory1Back from './assets/card-history1-back.png';
import cardHistory1Success from './assets/card-history1-success.png';
import cardHistory1Fail from './assets/card-history1-fail.png';
// import cardSecret from './assets/card-secret.png';
import cardHistory2Front from './assets/card-history2-front.png';
import cardHistory2Back from './assets/card-history2-back.png';
import cardHistory2Success from './assets/card-history2-success.png';
import cardHistory2Fail from './assets/card-history2-fail.png';
import cardChemistry2Front from './assets/card-chemistry2-front.png';
import cardChemistry2Back from './assets/card-chemistry2-back.png';
import cardChemistry2Success from './assets/card-chemistry2-success.png';
import cardChemistry2Fail from './assets/card-chemistry2-fail.png';
import cardBiology2Front from './assets/card-biology2-front.png';
import cardBiology2Back from './assets/card-biology2-back.png';
import cardBiology2Success from './assets/card-biology2-success.png';
import cardBiology2Fail from './assets/card-biology2-fail.png';
import cardPhysics2Front from './assets/card-physics2-front.png';
import cardPhysics2Back from './assets/card-physics2-back.png';
import cardPhysics2Success from './assets/card-physics2-success.png';
import cardPhysics2Fail from './assets/card-physics2-fail.png';
import cardInvFront from './assets/card-investments-front.png';
import cardInvBack from './assets/card-investments-back.png';
import cardInvSuccess from './assets/card-investments-success.png';
import cardInvFail from './assets/card-investments-fail.png';
import cardEcoFront from './assets/card-economics-front.png';
import cardEcoBack from './assets/card-economics-back.png';
import cardEcoSuccess from './assets/card-economics-success.png';
import cardEcoFail from './assets/card-economics-fail.png';
import cardFinFront from './assets/card-finance-front.png';
import cardFinBack from './assets/card-finance-back.png';
import cardFinSuccess from './assets/card-finance-success.png';
import cardFinFail from './assets/card-finance-fail.png';

import startMedia2Page1 from './assets/start-media2-page1.png';
import startMedia2Page2 from './assets/start-media2-page2.png';
import startMedia2Page3 from './assets/start-media2-page3.png';

import startMedia3Page1 from './assets/start-media3-page1.png';
import startMedia3Page2 from './assets/start-media3-page2.png';
import startMedia3Page3 from './assets/start-media3-page3.png';

import { cards, cards2, cards3 } from './cards';
import { questions, questions2, questions3 } from './questions';
import { ICard } from './helpers';
import { Memory } from './components/Memory';
import PuzzleGame from './components/PuzzleGame';
import HanoiTower from './components/HanoiTower';
import Knights from './components/Knights';
// import Knights from './components/Knights';

const btnBg2 = (
	<svg width="189" height="76" viewBox="0 0 189 76" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g filter="url(#filter0_d_28_252)">
			<path d="M7 0.317383L178.831 13.8274L188.119 68.7121H18.3991L7 0.317383Z" fill="url(#paint0_linear_28_252)" />
		</g>
		<defs>
			<filter
				id="filter0_d_28_252"
				x="0.24497"
				y="0.317383"
				width="187.874"
				height="75.1498"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dx="-5.91065" dy="5.91065" />
				<feGaussianBlur stdDeviation="0.422189" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.0058334 0 0 0 0 0.145833 0 0 0 0.4 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_28_252" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_28_252" result="shape" />
			</filter>
			<linearGradient
				id="paint0_linear_28_252"
				x1="97.5596"
				y1="0.317383"
				x2="97.5596"
				y2="68.7121"
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#FFB118" />
				<stop offset="1" stopColor="#FF5175" />
			</linearGradient>
		</defs>
	</svg>
);

const btnBg1 = (
	<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 192 53">
		<path
			fillRule="evenodd"
			fill="rgb(255, 107, 247)"
			d="M0.000,10.000 L192.000,0.000 L172.000,53.000 L11.000,52.000 L0.000,10.000 Z"
		/>
	</svg>
);

const btnBg3 = (
	<svg width="487" height="195" viewBox="0 0 487 195" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g filter="url(#filter0_d_432_1135)">
			<path d="M18 0L462.685 34.963L486.722 177H47.5L18 0Z" fill="#FFDD00" />
		</g>
		<defs>
			<filter
				id="filter0_d_432_1135"
				x="0.518519"
				y="0"
				width="486.204"
				height="194.481"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dx="-15.2963" dy="15.2963" />
				<feGaussianBlur stdDeviation="1.09259" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.0058334 0 0 0 0 0.145833 0 0 0 0.4 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_432_1135" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_432_1135" result="shape" />
			</filter>
		</defs>
	</svg>
);

const noBtnPhysics = (
	<svg width="144" height="75" viewBox="0 0 144 75" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g filter="url(#filter0_d_330_866)">
			<path d="M144 0L14.0256 13.4321L7 68H135.378L144 0Z" fill="url(#paint0_linear_330_866)" />
		</g>
		<defs>
			<filter
				id="filter0_d_330_866"
				x="0.24497"
				y="0"
				width="143.755"
				height="74.755"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dx="-5.91065" dy="5.91065" />
				<feGaussianBlur stdDeviation="0.422189" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.0058334 0 0 0 0 0.145833 0 0 0 0.4 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_330_866" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_330_866" result="shape" />
			</filter>
			<linearGradient id="paint0_linear_330_866" x1="75.5" y1="0" x2="75.5" y2="68" gradientUnits="userSpaceOnUse">
				<stop stopColor="#FFB118" />
				<stop offset="1" stopColor="#FF5175" />
			</linearGradient>
		</defs>
	</svg>
);

const yesBtnPhysics = (
	<svg width="144" height="75" viewBox="0 0 144 75" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g filter="url(#filter0_d_330_863)">
			<path d="M7 68L136.974 54.5679L144 0H15.6224L7 68Z" fill="#00c0cc" />
		</g>
		<defs>
			<filter
				id="filter0_d_330_863"
				x="0.24497"
				y="0"
				width="143.755"
				height="74.755"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dx="-5.91065" dy="5.91065" />
				<feGaussianBlur stdDeviation="0.422189" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.0058334 0 0 0 0 0.145833 0 0 0 0.4 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_330_863" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_330_863" result="shape" />
			</filter>
		</defs>
	</svg>
);

const noBtnCosmos = (
	<svg width="144" height="75" viewBox="0 0 144 75" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g filter="url(#filter0_d_517_1472)">
			<path d="M144 0L14.0256 13.4321L7 68H135.378L144 0Z" fill="#FF5EFE" />
		</g>
		<defs>
			<filter
				id="filter0_d_517_1472"
				x="0.24497"
				y="0"
				width="143.755"
				height="74.755"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dx="-5.91065" dy="5.91065" />
				<feGaussianBlur stdDeviation="0.422189" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.0058334 0 0 0 0 0.145833 0 0 0 0.4 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_517_1472" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_517_1472" result="shape" />
			</filter>
		</defs>
	</svg>
);

const yesBtnCosmos = (
	<svg width="144" height="75" viewBox="0 0 144 75" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g filter="url(#filter0_d_517_1469)">
			<path d="M7 68L136.974 54.5679L144 0H15.6224L7 68Z" fill="#6A38AD" />
		</g>
		<defs>
			<filter
				id="filter0_d_517_1469"
				x="0.24497"
				y="0"
				width="143.755"
				height="74.755"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dx="-5.91065" dy="5.91065" />
				<feGaussianBlur stdDeviation="0.422189" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.0058334 0 0 0 0 0.145833 0 0 0 0.4 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_517_1469" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_517_1469" result="shape" />
			</filter>
		</defs>
	</svg>
);

const noBtnTinkoff = (
	<svg width="144" height="75" viewBox="0 0 144 75" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g filter="url(#filter0_d_517_1460)">
			<path d="M144 0L14.0256 13.4321L7 68H135.378L144 0Z" fill="#FFDD00" />
		</g>
		<defs>
			<filter
				id="filter0_d_517_1460"
				x="0.24497"
				y="0"
				width="143.755"
				height="74.755"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dx="-5.91065" dy="5.91065" />
				<feGaussianBlur stdDeviation="0.422189" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.0058334 0 0 0 0 0.145833 0 0 0 0.4 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_517_1460" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_517_1460" result="shape" />
			</filter>
		</defs>
	</svg>
);

const yesBtnTinkoff = (
	<svg width="144" height="75" viewBox="0 0 144 75" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g filter="url(#filter0_d_517_1457)">
			<path d="M7 68L136.974 54.5679L144 0H15.6224L7 68Z" fill="#007AEA" />
		</g>
		<defs>
			<filter
				id="filter0_d_517_1457"
				x="0.24497"
				y="0"
				width="143.755"
				height="74.755"
				filterUnits="userSpaceOnUse"
				colorInterpolationFilters="sRGB"
			>
				<feFlood floodOpacity="0" result="BackgroundImageFix" />
				<feColorMatrix
					in="SourceAlpha"
					type="matrix"
					values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					result="hardAlpha"
				/>
				<feOffset dx="-5.91065" dy="5.91065" />
				<feGaussianBlur stdDeviation="0.422189" />
				<feComposite in2="hardAlpha" operator="out" />
				<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.0058334 0 0 0 0 0.145833 0 0 0 0.4 0" />
				<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_517_1457" />
				<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_517_1457" result="shape" />
			</filter>
		</defs>
	</svg>
);

export const getTheme = (qid: string): Record<string, any> => {
	if (qid === '21' || qid === '22') {
		return {
			bg: bg,
			cards: cards as ICard[],
			questions: questions,
			themeColor: 'rgb(100, 3, 229)',
			themeColorLight: 'rgb(176, 80, 250)',
			themeBgColor: 'rgb(19, 0, 37)',
			innerFrame: innerFrame1,
			arcade1: {
				component: <Memory />,
				name: 'Мемори',
				desc: (
					<p>
						Кликай на карточки и запоминай изображения на них. Твоя задача — найти все парные картинки и одновременно
						перевернуть их.<br></br>Торопись, время ограничено!
					</p>
				),
			},
			arcade2: {
				component: <PuzzleGame />,
				name: 'Пятнашки',
				desc: (
					<p>
						Используя одно свободное поле, ты можешь менять фишки местами. Задача — расставить фишки по порядку, от 1 до
						15, слева-направо до окончания работы таймера!
					</p>
				),
				contentStyle: {
					display: 'block',
					left: 0,
					bottom: 0,
					top: '25%',
					width: '100%',
				},
			},
			start: {
				h4: <h4 style={{ fontSize: '2.4rem', color: 'rgb(176, 80, 250)', margin: '1rem 0' }}>ПРАВИЛА КВЕСТА</h4>,
				h1Style: { fontSize: '4rem', color: 'white', margin: '1rem 0' },
				textStyle: { padding: '1% 4% 5%', fontFamily: 'Gilroy', fontSize: '1.7rem' },
				contentStyle: { fontFamily: 'Gilroy', fontSize: '1.7rem' },
				innerContentStyle: {},
				frameStyle: `url(${rectFrame1}) no-repeat 50% 50%/94%`,
				btnBg: btnBg1,
				btnStyle: { width: '25%', height: '9%', margin: '32px 0' },
				questName: 'Космический квест',
				nav: {
					active: 'rgb(176, 80, 250)',
					default: '#00589a',
				},
				content: {
					1: (
						<>
							<p>
								Квест — это новый вид заданий. Необходимо добраться до космического корабля и собрать максимальное
								количество кристаллов. Чтобы двигаться по карте, выбери направление и кликай на карточку.
							</p>
						</>
					),
					2: (
						<>
							<p>
								Есть 2 вида карточек: вопросы и мини-игры. Выбери тему и отвечай на вопросы. Спеши, ведь время
								ограничено: если ты не успеешь ответить, потеряешь кристалл.
							</p>
							<p>
								Мини-игры — классные и увлекательные. За выигрыш в них ты получишь дополнительный кристалл, но они
								увеличат путь до финиша, и ты можешь растерять кристаллы. Выбери свою стратегию, которая позволит
								принести максимум кристаллов на корабль.
							</p>
						</>
					),
					3: (
						<>
							<p>
								В правом верхнем углу размещена карта с основными элементами и твоим положением на ней. Внизу — твои
								текущие кристаллы.
							</p>
							<p>
								В результате ты получишь по 100 баллов за каждый кристалл в соответствующую тематическую ветку конкурса.
								Желаем удачи в путешествии!
							</p>
						</>
					),
					win: (crystals: number) => (
						<p>
							Ура! Ты достойно прошёл задание и доставил {crystals} Кристаллов на корабль. За каждый из них тебе
							полагается 100 баллов, то есть ты получишь {crystals || crystals === 0 ? crystals * 100 : 0} баллов в
							копилку основного рейтинга Конкурса!
						</p>
					),
					lose: (
						<p>
							Кажется, что-то пошло не так. Кристаллов совсем не осталось :( <br></br>Мы вынуждены прервать твою миссию.
							Ты очень старался, поэтому мы начислим тебе 100 баллов за попытку.
						</p>
					),
				},
			},
			arrowColor: '#2e0e5d',
			map: {
				img: map1,
				persona: persona1,
				finishImg: '',
			},
			card: {
				astronomy: {
					front: cardAstronomy1Front,
					back: cardAstronomy1Back,
					success: cardAstronomy1Success,
					fail: cardAstronomy1Fail,
				},
				history: {
					front: cardHistory1Front,
					back: cardHistory1Back,
					success: cardHistory1Success,
					fail: cardHistory1Fail,
				},
				physics: {
					front: cardPhysics1Front,
					back: cardPhysics1Back,
					success: cardPhysics1Success,
					fail: cardPhysics1Fail,
				},
				chemistry: {
					front: cardChemistry1Front,
					back: cardChemistry1Back,
					success: cardChemistry1Success,
					fail: cardChemistry1Fail,
				},
				arcade: btnCrystal,
				finish: '',
			},
			quiz: {
				rightColorBtnBorder: '#52EDD0',
				rightColorBtnBg: '#30ADD7',
				wrongColorBtnBorder: '#E91980',
				wrongColorBtnBg: '#9F1E5E',
			},
			minimap: {
				img: minimap,
				ava: ava,
				arcade1Mini: {
					img: crystalMini1,
					posStyle: { top: '34%', left: '15%' },
				},
				arcade2Mini: {
					img: crystalMini2,
					posStyle: { top: '2.5%', left: '31%' },
				},
				finishMini: { img: rocketMini },
			},
			quitArcadeBtnNo: noBtnCosmos,
			quitArcadeBtnYes: yesBtnCosmos,
		};
	}
	if (qid === '32' || qid === '33') {
		return {
			bg: bg2,
			cards: cards2,
			questions: questions2,
			themeColor: '#00a7cc',
			themeColorLight: '#00c0cc',
			themeBgColor: '#001b3a',
			arrowColor: '#0163C8',
			arcade1: {
				component: <HanoiTower />,
				name: 'Ханойская башня',
				desc: (
					<p>
						Перемести пирамиду на правый стержень за отведённое время. За один ход можно перемещать одно кольцо и нельзя
						ставить большее кольцо на меньшее.
					</p>
					// <p>
					// 	Перенеси пирамиду из 6 колец на третий стержень<br></br>Торопись, время ограничено!
					// </p>
				),
				contentStyle: {
					display: 'block',
					left: 0,
					bottom: 0,
					top: '25%',
					width: '100%',
				},
			},
			arcade2: {
				component: <PuzzleGame />,
				name: 'Пятнашки',
				desc: (
					<p>
						Используя одно свободное поле, ты можешь менять фишки местами. Задача — расставить фишки по порядку, от 1 до
						15, слева-направо до окончания работы таймера!
					</p>
				),
				contentStyle: {
					display: 'block',
					left: 0,
					bottom: 0,
					top: '25%',
					width: '100%',
				},
			},
			start: {
				h4: <h4 style={{ fontSize: '2.4rem', color: 'white', margin: '1rem 0' }}>ПРАВИЛА КВЕСТА</h4>,
				h1Style: { fontSize: '4rem', color: '#04BCD5', margin: '0 0 1rem', fontFamily: 'Gilroy' },
				textStyle: { padding: '1% 4% 5%', fontFamily: 'Gilroy', fontSize: '1.7rem' },
				contentStyle: { marginLeft: 0 },
				innerContentStyle: { margin: 0 },
				frameStyle: `url(${rectFrame2}) no-repeat 50% 50%/88%`,
				btnBg: btnBg2,
				btnStyle: { width: '16%', height: '6%', margin: '32px 0' },
				nav: {
					active: '#ff6166',
					default: '#00589a',
				},
				questName: 'КВЕСТ ПО ФИЗИКЕ',
				content: {
					1: (
						<>
							<p style={{ color: '#29b2ff', margin: '0 auto', fontFamily: 'Gilroy', fontSize: '1.7rem' }}>
								Новое интересное задание-квест
							</p>
							<img
								src={startMedia2Page1}
								alt="Quest Description: Page 1"
								style={{ width: '90%', paddingLeft: '10%' }}
							/>
						</>
					),
					2: (
						<>
							<p style={{ color: 'transparent', margin: '0 auto', fontFamily: 'Gilroy', fontSize: '1.7rem' }}>
								Невидимый текст
							</p>
							<img
								src={startMedia2Page2}
								alt="Quest Description: Page 2"
								style={{ width: '90%', paddingLeft: '10%' }}
							/>
						</>
					),
					3: (
						<>
							<p style={{ color: 'transparent', margin: '0 auto', fontFamily: 'Gilroy', fontSize: '1.7rem' }}>
								Невидимый текст
							</p>
							<img
								src={startMedia2Page3}
								alt="Quest Description: Page 3"
								style={{ width: '90%', paddingLeft: '10%' }}
							/>
						</>
					),
					win: (points: number) => (
						<p>
							Ура! Ты достойно прошёл задание и доставил {points} протонов в коллайдер. За каждый из них тебе полагается
							100 баллов, то есть ты получишь {points || points === 0 ? points * 100 : 0} баллов в копилку основного
							рейтинга Конкурса!
						</p>
					),
					lose: (
						<p>
							Кажется, что-то пошло не так. Протонов совсем не осталось :( <br></br>Мы вынуждены прервать твою миссию.
							Ты очень старался, поэтому мы начислим тебе 100 баллов за попытку.
						</p>
					),
				},
			},
			map: {
				img: map2,
				persona: persona2,
			},
			quiz: {
				rightColorBtnBorder: '#9AFFD5',
				rightColorBtnBg: '#30D7AF',
				wrongColorBtnBorder: '#E94B19',
				wrongColorBtnBg: '#B1362E',
			},
			card: {
				astronomy: {
					front: cardAstronomy1Front,
					back: cardAstronomy1Back,
					success: cardAstronomy1Success,
					fail: cardAstronomy1Fail,
				},
				history: {
					front: cardHistory2Front,
					back: cardHistory2Back,
					success: cardHistory2Success,
					fail: cardHistory2Fail,
				},
				physics: {
					front: cardPhysics2Front,
					back: cardPhysics2Back,
					success: cardPhysics2Success,
					fail: cardPhysics2Fail,
				},
				chemistry: {
					front: cardChemistry2Front,
					back: cardChemistry2Back,
					success: cardChemistry2Success,
					fail: cardChemistry2Fail,
				},
				biology: {
					front: cardBiology2Front,
					back: cardBiology2Back,
					success: cardBiology2Success,
					fail: cardBiology2Fail,
				},
				arcade: arcadeBtn2,
				finish: collider,
			},
			minimap: {
				img: minimap2,
				ava: ava2,
				arcade1Mini: {
					img: protonMini,
					posStyle: { top: '9%', left: '54%' },
				},
				arcade2Mini: {
					img: protonMini,
					posStyle: { top: '27%', left: '4.5%' },
				},
				finishMini: { img: colliderMini },
			},
			quitArcadeBtnYes: yesBtnPhysics,
			quitArcadeBtnNo: noBtnPhysics,
		};
	}
	if (qid === '34') {
		return {
			bg: bg3,
			// themeColor: '#ffdd00',
			themeColor: '#007aea',
			arrowColor: '#ffdd00',
			themeBgColor: '#151e40',
			themeColorLight: '#2e9bff',
			cards: cards3,
			questions: questions3,
			map: { img: map3, persona: persona3, personaPosStyle: { top: '36%', width: '16%' } },
			minimap: {
				img: minimap3,
				ava: ava3,
				arcade1Mini: {
					img: coinMini,
					posStyle: { top: '3%', left: '54%' },
				},
				arcade2Mini: {
					img: coinMini,
					posStyle: { top: '34%', left: '16.5%' },
				},
				finishMini: { img: caseMini, posStyle: { top: '36%', right: '7%', width: '24%' } },
			},
			card: {
				investments: {
					front: cardInvFront,
					back: cardInvBack,
					success: cardInvSuccess,
					fail: cardInvFail,
				},
				finances: {
					front: cardFinFront,
					back: cardFinBack,
					success: cardFinSuccess,
					fail: cardFinFail,
				},
				economics: {
					front: cardEcoFront,
					back: cardEcoBack,
					success: cardEcoSuccess,
					fail: cardEcoFail,
				},
				arcade: arcadeBtn3,
				finish: caser,
			},
			start: {
				questName: 'ФИНАНСОВЫЙ КВЕСТ',
				btnBg: btnBg3,
				h4: <h4 style={{ fontSize: '2.4rem', color: 'white', margin: '1rem 0' }}>ПРАВИЛА КВЕСТА</h4>,
				h1Style: { fontSize: '4rem', color: '#2e9bff', margin: '0 0 1rem', fontFamily: 'Gilroy' },
				textStyle: { padding: '1% 4% 5%', fontFamily: 'Gilroy', fontSize: '1.7rem' },
				contentStyle: { marginLeft: 0 },
				innerContentStyle: { margin: 0 },
				frameStyle: `url(${rectFrame3}) no-repeat 50% 50%/88%`,
				btnStyle: { width: '16%', height: '6%', margin: '32px 0' },
				nav: {
					active: '#ffdd00',
					default: '#00589a',
				},
				content: {
					1: (
						<>
							<p style={{ color: 'white', margin: '0 auto', fontFamily: 'Gilroy', fontSize: '1.7rem' }}>
								Новое интересное задание-квест
							</p>
							<img
								src={startMedia3Page1}
								alt="Quest Description: Page 1"
								style={{ width: '90%', paddingLeft: '10%' }}
							/>
						</>
					),
					2: (
						<>
							<p style={{ color: 'transparent', margin: '0 auto', fontFamily: 'Gilroy', fontSize: '1.7rem' }}>
								Невидимый текст
							</p>
							<img
								src={startMedia3Page2}
								alt="Quest Description: Page 2"
								style={{ width: '90%', paddingLeft: '10%' }}
							/>
						</>
					),
					3: (
						<>
							<p style={{ color: 'transparent', margin: '0 auto', fontFamily: 'Gilroy', fontSize: '1.7rem' }}>
								Невидимый текст
							</p>
							<img
								src={startMedia3Page3}
								alt="Quest Description: Page 3"
								style={{ width: '90%', paddingLeft: '10%' }}
							/>
						</>
					),
					win: (points: number) => (
						<p>
							Ура! Ты достойно прошёл задание и доставил {points} монет в портфель. За каждый из них тебе полагается 100
							баллов, то есть ты получишь {points || points === 0 ? points * 100 : 0} баллов в копилку основного
							рейтинга Конкурса!
						</p>
					),
					lose: (
						<p>
							Кажется, что-то пошло не так. Монет совсем не осталось :( <br></br>Мы вынуждены прервать твою миссию. Ты
							очень старался, поэтому мы начислим тебе 100 баллов за попытку.
						</p>
					),
				},
			},
			quiz: {
				rightColorBtnBorder: '#00ffff',
				rightColorBtnBg: '#30cdd7',
				wrongColorBtnBorder: '#E94B19',
				wrongColorBtnBg: 'transparent',
			},
			arcade1: {
				component: <Memory />,
				name: 'Мемори',
				desc: (
					<p>
						Кликай на карточки и запоминай изображения на них. Твоя задача — найти все парные картинки и одновременно
						перевернуть их.<br></br>Торопись, время ограничено!
					</p>
				),
			},
			arcade2: {
				component: <Knights />,
				name: 'Рыцари',
				desc: 'Перемести фигуры с нижнего ряда на верхний и наоборот за минимальное количество ходов',
				contentStyle: {
					display: 'block',
					left: 0,
					bottom: 0,
					top: '25%',
					width: '100%',
				},
			},
			quitArcadeBtnNo: noBtnTinkoff,
			quitArcadeBtnYes: yesBtnTinkoff,
		};
	}
	return {};
};
