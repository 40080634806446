/* eslint-disable no-restricted-globals */
import axios from 'axios';

export const fetchGameBegin = async (tokenPub: string, qid: string): Promise<string | void> => {
	try {
		const {
			data: { code, message },
		}: { data: { code: number; message: string } } = await axios.get(
			`../back/upd.php?token_pub=${tokenPub}&do=GetQuest&qid=${qid}`
		);
		switch (code) {
			case 200:
				return 'ok';
			case 403:
			case 401:
				location.replace(`../auth`);
				break;
			case 404:
			default:
				alert(message);
				return 'error';
		}
	} catch (e) {
		console.error((e as unknown as { response: { message: string } }).response);
		if ((e as unknown as { response: { message: string } })?.response?.message) {
			alert((e as unknown as { response: { message: string } })?.response?.message || '');
		}
	}
};

export const fetchGameEnd = async (tokenPub: string, qid: string, crystalsNum: number): Promise<string | void> => {
	try {
		const {
			data: { code, message },
		}: { data: { code: number; message: string } } = await axios.get(
			`../back/upd.php?token_pub=${tokenPub}&do=SendQuestA&qid=${qid}&a=${crystalsNum}`
		);
		switch (code) {
			case 200:
				return 'ok';
			case 403:
			case 401:
				location.replace(`../auth`);
				break;
			case 404:
			case 405:
			default:
				alert(message);
				return 'error';
		}
	} catch (e) {
		console.error((e as unknown as { response: { message: string } }).response);
		if ((e as unknown as { response: { message: string } })?.response?.message) {
			alert((e as unknown as { response: { message: string } })?.response?.message || '');
		}
	}
};
