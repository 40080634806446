// опытным путем определил относительную величину 1/8 от размера увеличенной карты (формулу не вывел, не получилось)
export type Nav = 'left' | 'right' | 'down' | 'up';
export type Topic =
	| 'arcade'
	| 'astronomy'
	| 'chemistry'
	| 'biology'
	| 'physics'
	| 'history'
	| 'investments'
	| 'finances'
	| 'economics'
	| 'secret'
	| 'finish';

const shift = ['0%', '20.3%', '40.3%', '60%', '80%', '100%'];

export const mapBackgroundShift = shift.reduce<Record<string, Array<string | number>>>((acc, curr, i, orig) => {
	const row = i + 1;
	acc[`${row}.1`] = [orig[0], curr];
	acc[`${row}.2`] = [orig[1], curr];
	acc[`${row}.3`] = [orig[2], curr];
	acc[`${row}.4`] = [orig[3], curr];
	acc[`${row}.5`] = [orig[4], curr];
	acc[`${row}.6`] = [orig[5], curr];
	return acc;
}, {});

export const minimapCurrentPosShift = Array(8)
	.fill('')
	.reduce<Record<string, Array<string | number>>>((acc, curr, i, orig) => {
		const row = i + 1;
		const shiftValueLeft = 0.125;
		// это не 1/8 карты, как по горизонтали, а контейнер выше контейнера миникарты, чтобы избежать наложения opacity: 50%
		const shiftValueTop = 0.062;
		for (let n = 0; n < orig.length; n++) {
			acc[`${row}.${n + 1}`] = [n * shiftValueLeft * 100 + '%', i * shiftValueTop * 100 + '%'];
		}
		return acc;
	}, {});

export const getPosition = (current: string, to: Nav): string => {
	switch (to) {
		case 'down':
			return +current.charAt(0) + 1 + current.slice(1);
		case 'up':
			return +current.charAt(0) - 1 + current.slice(1);
		case 'left':
			return current.slice(0, 2) + (+current.charAt(2) - 1);
		case 'right':
			return current.slice(0, 2) + (+current.charAt(2) + 1);
		default:
			return '';
	}
};

export interface ICard {
	id: string;
	position: string;
	topic: Topic;
}

export const cardNames: Record<Topic, string> = {
	secret: '',
	arcade: '',
	finish: '',
	astronomy: 'Астрономия',
	biology: 'Биология',
	chemistry: 'Химия',
	history: 'История',
	physics: 'Физика',
	investments: 'Инвестиции',
	economics: 'Экономика',
	finances: 'Финансы',
};

export type Variant = 'var1' | 'var2' | 'var3' | 'var4';

export type Question = Record<
	string,
	{ question: string; var1: string; var2: string; var3: string; var4: string; rightVar: string }
>;
