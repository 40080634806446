import { useContext, useState } from 'react';
import { AppContext } from '../../storeContext';
import { ArcadeBase } from '../ArcadeBase';
import Board from './components/Board';

const Knights = () => {
	const { theme, setIsShowArcade2 } = useContext(AppContext);
	const [status, setStatus] = useState<string>('playing');
	return (
		<ArcadeBase
			startTime={300}
			defaultIsShowDescription={false}
			setIsShowArcade={setIsShowArcade2}
			name={theme.arcade2.name}
			desc={theme.arcade2.desc}
			winCond={status === 'win'}
			contentStyle={theme.arcade2.contentStyle}
		>
			<div
				className="puzzle-title"
				style={{
					position: 'absolute',
					fontFamily: 'Gilroy',
					fontSize: '1.67rem',
					top: '-27%',
					left: '23%',
					color: 'white',
					width: '57%',
				}}
			>
				{status === 'win' ? 'Ура! Вы выиграли!' : theme.arcade2.desc}
			</div>
			<Board setStatus={setStatus} status={status} />
		</ArcadeBase>
	);
};
export default Knights;
