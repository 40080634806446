import { Dispatch, SetStateAction, useContext } from 'react';
import { AppContext } from '../storeContext';

interface Props {
	text: string;
	id: string;
	rightVariant: string;
	selectedVar: string | null;
	setIsSelected: Dispatch<SetStateAction<string | null>>;
	showRightVar: boolean;
}

export const QuizVariantBtn = ({
	text,
	id,
	rightVariant,
	setIsSelected,
	selectedVar,
	showRightVar,
}: Props): JSX.Element => {
	const { theme } = useContext(AppContext);

	const failStyles = { fill: theme.quiz.wrongColorBtnBg, stroke: theme.quiz.wrongColorBtnBorder };
	const successStyles = { fill: theme.quiz.rightColorBtnBg, stroke: theme.quiz.rightColorBtnBorder };

	const btn1SVG = (result?: boolean): JSX.Element => (
		<svg width="541" height="117" viewBox="0 0 541 117" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6.31924 97.0488L45.7153 16.5406L504.737 4.08283L535.658 112.839L6.31924 97.0488Z"
				fill={result === undefined ? 'transparent' : result ? successStyles.fill : failStyles.fill}
				stroke={result === undefined ? theme.themeColor : result ? successStyles.stroke : failStyles.stroke}
				strokeWidth="12"
			/>
		</svg>
	);

	const btn2SVG = (result?: boolean): JSX.Element => (
		<svg width="541" height="116" viewBox="0 0 541 116" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M534.657 19.8141L495.294 99.5663L36.256 111.918L5.35425 4.1601L534.657 19.8141Z"
				fill={result === undefined ? 'transparent' : result ? successStyles.fill : failStyles.fill}
				stroke={result === undefined ? theme.themeColor : result ? successStyles.stroke : failStyles.stroke}
				strokeWidth="12"
			/>
		</svg>
	);

	const btn3SVG = (result?: boolean): JSX.Element => (
		<svg width="541" height="116" viewBox="0 0 541 116" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6.3432 19.8141L45.7058 99.5663L504.744 111.918L535.646 4.1601L6.3432 19.8141Z"
				fill={result === undefined ? 'transparent' : result ? successStyles.fill : failStyles.fill}
				stroke={result === undefined ? theme.themeColor : result ? successStyles.stroke : failStyles.stroke}
				strokeWidth="12"
			/>
		</svg>
	);

	const btn4SVG = (result?: boolean): JSX.Element => (
		<svg width="541" height="116" viewBox="0 0 541 116" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M534.657 19.8141L495.294 99.5663L36.256 111.918L5.35425 4.1601L534.657 19.8141Z"
				fill={result === undefined ? 'transparent' : result ? successStyles.fill : failStyles.fill}
				stroke={result === undefined ? theme.themeColor : result ? successStyles.stroke : failStyles.stroke}
				strokeWidth="12"
			/>
		</svg>
	);

	const renderClass =
		!selectedVar || selectedVar !== text
			? ''
			: selectedVar === text && selectedVar === rightVariant
			? 'success'
			: 'fail';

	const renderBtnBg: Record<string, () => JSX.Element> = {
		var1: () =>
			(selectedVar === text && selectedVar === rightVariant) || (showRightVar && rightVariant === text)
				? btn1SVG(true)
				: !selectedVar || selectedVar !== text
				? btn1SVG()
				: btn1SVG(false),
		var2: () =>
			(selectedVar === text && selectedVar === rightVariant) || (showRightVar && rightVariant === text)
				? btn2SVG(true)
				: !selectedVar || selectedVar !== text
				? btn2SVG()
				: btn2SVG(false),
		var3: () =>
			(selectedVar === text && selectedVar === rightVariant) || (showRightVar && rightVariant === text)
				? btn3SVG(true)
				: !selectedVar || selectedVar !== text
				? btn3SVG()
				: btn3SVG(false),
		var4: () =>
			(selectedVar === text && selectedVar === rightVariant) || (showRightVar && rightVariant === text)
				? btn4SVG(true)
				: !selectedVar || selectedVar !== text
				? btn4SVG()
				: btn4SVG(false),
	};

	return (
		<div
			style={selectedVar ? { pointerEvents: 'none' } : {}}
			onClick={() => setIsSelected(text)}
			className={`variant-${id} ${renderClass}`}
		>
			{renderBtnBg[id]()}
			<button>{text}</button>
		</div>
	);
};
