import { mapBackgroundShift, minimapCurrentPosShift } from '../helpers';
import { CSSProperties, useContext } from 'react';
import { AppContext } from '../storeContext';

export const Minimap = ({ currentPosition }: { currentPosition: string }): JSX.Element => {
	const { theme } = useContext(AppContext);
	const minimapStyle: CSSProperties = {
		position: 'relative',
		width: '100%',
		height: 0,
		paddingBottom: '100%',
		background: `url(${theme.minimap.img}) no-repeat 0% 0%/cover`,
		backgroundSize: 'cover',
		opacity: '50%',
	};
	const currentStyle: CSSProperties = {
		position: 'absolute',
		width: 'calc((3/8.5)*100%)',
		height: 0,
		paddingBottom: 'calc((3/8.5)*100%)',
		border: `5px solid ${theme.themeColor}`,
		backgroundImage: `url(${theme.minimap.img})`,
		backgroundSize: 'calc((8.4/3)*100%)',
	};

	return (
		<>
			<div className="minimap" style={minimapStyle}></div>
			<div
				className="current"
				style={{
					...currentStyle,
					backgroundPosition: mapBackgroundShift[currentPosition]
						.map((v, i) => {
							if ((currentPosition.includes('.1') || currentPosition.includes('.2')) && i === 0) {
								return `calc(${v} - 4px)`;
							} else if (i === 0) {
								return v;
							} else if ((currentPosition.includes('1.') || currentPosition.includes('2.')) && i === 1) {
								return `calc(${v} - 3px)`;
							} else if (
								(currentPosition.includes('3.') || currentPosition.includes('4.') || currentPosition.includes('5.')) &&
								i === 1
							) {
								return `calc(${v} - 0px)`;
							} else if (currentPosition.includes('6.') && i === 1) {
								return `calc(${v} + 3px)`;
							}
							return '-3px';
						})
						.join(' '),
					left: minimapCurrentPosShift[currentPosition][0],
					top: minimapCurrentPosShift[currentPosition][1],
				}}
			>
				<img
					src={theme.minimap.ava}
					alt="Avatar"
					data-pos={currentPosition}
					style={{
						position: 'absolute',
						width: '33%',
						top: '33%',
						left: '31%',
					}}
				/>
			</div>
			<img
				src={theme.minimap.finishMini.img}
				alt="finish"
				style={{
					position: 'absolute',
					top: '29%',
					right: '0%',
					width: 'calc((1/2.5) * 100%)',
					...theme.minimap.finishMini.posStyle,
				}}
			/>
			<img
				src={theme.minimap.arcade1Mini.img}
				alt="arcade 1"
				style={{
					...theme.minimap.arcade1Mini.posStyle,
					position: 'absolute',
					width: 'calc((1/3.5) * 100%)',
				}}
			/>
			<img
				src={theme.minimap.arcade2Mini.img}
				alt="arcade 2"
				style={{
					...theme.minimap.arcade2Mini.posStyle,
					position: 'absolute',
					width: 'calc((1/3.5) * 100%)',
				}}
			/>
		</>
	);
};
