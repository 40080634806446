import { Grid } from './generateGrid';
import { Tile } from './Tile';
// import Winner from './../Winner/Winner';
import './Tiles.css';

interface Props {
	tiles: Grid;
	onTileClick: (coord: number[]) => void;
}

export const Tiles = ({ tiles, onTileClick }: Props): JSX.Element => {
	return (
		<div className="tiles-wrapper rounded">
			<div className="tiles">
				{tiles.map((item, index) => {
					return <Tile key={'item:' + index} {...item} onClick={() => item.value && onTileClick(item.coord)} />;
				})}
			</div>
			{/* <Winner /> */}
		</div>
	);
};
