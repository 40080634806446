import { ICard } from './helpers';

export const cards: ICard[] = [
	{ id: 'H1', position: '1.2', topic: 'history' },
	{ id: 'AR1', position: '1.3', topic: 'arcade' },
	{ id: 'CH1', position: '1.4', topic: 'chemistry' },
	{ id: 'A1', position: '1.5', topic: 'astronomy' },
	{ id: 'H2', position: '1.6', topic: 'history' },
	{ id: 'CH2', position: '2.1', topic: 'chemistry' },
	{ id: 'PH1', position: '2.2', topic: 'physics' },
	{ id: 'A2', position: '2.3', topic: 'astronomy' },
	{ id: 'PH2', position: '2.4', topic: 'physics' },
	{ id: 'H3', position: '2.5', topic: 'history' },
	{ id: 'A3', position: '2.6', topic: 'astronomy' },
	{ id: 'A4', position: '3.1', topic: 'astronomy' },
	{ id: 'PH3', position: '3.2', topic: 'physics' },
	{ id: 'H4', position: '3.3', topic: 'history' },
	{ id: 'A5', position: '3.4', topic: 'astronomy' },
	{ id: 'CH3', position: '3.5', topic: 'chemistry' },
	{ id: 'PH4', position: '3.6', topic: 'physics' },
	{ id: 'H5', position: '4.1', topic: 'history' },
	{ id: 'CH4', position: '4.2', topic: 'chemistry' },
	{ id: 'PH5', position: '4.3', topic: 'physics' },
	{ id: 'CH5', position: '4.4', topic: 'chemistry' },
	{ id: 'H6', position: '4.5', topic: 'history' },
	{ id: 'A6', position: '4.6', topic: 'astronomy' },
	{ id: 'PH6', position: '5.1', topic: 'physics' },
	{ id: 'A7', position: '5.2', topic: 'astronomy' },
	{ id: 'H7', position: '5.3', topic: 'history' },
	{ id: 'A8', position: '5.4', topic: 'astronomy' },
	{ id: 'CH6', position: '5.5', topic: 'chemistry' },
	{ id: 'PH7', position: '5.6', topic: 'physics' },
	{ id: 'CH7', position: '6.1', topic: 'chemistry' },
	{ id: 'AR2', position: '6.2', topic: 'arcade' },
	{ id: 'PH8', position: '6.3', topic: 'physics' },
	{ id: 'CH8', position: '6.4', topic: 'chemistry' },
	{ id: 'H8', position: '6.5', topic: 'history' },
	{ id: 'FINISH', position: '6.6', topic: 'finish' },
];

export const cards2: ICard[] = [
	{ id: 'CH1', position: '1.2', topic: 'chemistry' },
	{ id: 'PH1', position: '1.3', topic: 'physics' },
	{ id: 'H1', position: '1.4', topic: 'history' },
	{ id: 'H2', position: '1.5', topic: 'history' },
	{ id: 'PH2', position: '1.6', topic: 'physics' },
	{ id: 'PH3', position: '2.1', topic: 'physics' },
	{ id: 'PH4', position: '2.2', topic: 'physics' },
	{ id: 'H3', position: '2.3', topic: 'history' },
	{ id: 'PH5', position: '2.4', topic: 'physics' },
	{ id: 'AR1', position: '2.5', topic: 'arcade' },
	{ id: 'H4', position: '2.6', topic: 'history' },
	{ id: 'CH2', position: '3.1', topic: 'chemistry' },
	{ id: 'H5', position: '3.2', topic: 'history' },
	{ id: 'PH6', position: '3.3', topic: 'physics' },
	{ id: 'H6', position: '3.4', topic: 'history' },
	{ id: 'PH7', position: '3.5', topic: 'physics' },
	{ id: 'H7', position: '3.6', topic: 'history' },
	{ id: 'PH8', position: '4.1', topic: 'physics' },
	{ id: 'PH9', position: '4.2', topic: 'physics' },
	{ id: 'H8', position: '4.3', topic: 'history' },
	{ id: 'PH10', position: '4.4', topic: 'physics' },
	{ id: 'PH11', position: '4.5', topic: 'physics' },
	{ id: 'CH3', position: '4.6', topic: 'chemistry' },
	{ id: 'AR2', position: '5.1', topic: 'arcade' },
	{ id: 'H9', position: '5.2', topic: 'history' },
	{ id: 'PH12', position: '5.3', topic: 'physics' },
	{ id: 'H10', position: '5.4', topic: 'history' },
	{ id: 'CH4', position: '5.5', topic: 'chemistry' },
	{ id: 'PH13', position: '5.6', topic: 'physics' },
	{ id: 'PH14', position: '6.1', topic: 'physics' },
	{ id: 'CH5', position: '6.2', topic: 'chemistry' },
	{ id: 'H11', position: '6.3', topic: 'history' },
	{ id: 'PH15', position: '6.4', topic: 'physics' },
	{ id: 'H12', position: '6.5', topic: 'history' },
	{ id: 'FINISH', position: '6.6', topic: 'finish' },
];

export const cards3: ICard[] = [
	{ id: 'I1', position: '1.2', topic: 'investments' },
	{ id: 'F1', position: '1.3', topic: 'finances' },
	{ id: 'E1', position: '1.4', topic: 'economics' },
	{ id: 'AR1', position: '1.5', topic: 'arcade' },
	{ id: 'I2', position: '1.6', topic: 'investments' },
	{ id: 'E2', position: '2.1', topic: 'economics' },
	{ id: 'F2', position: '2.2', topic: 'finances' },
	{ id: 'E3', position: '2.3', topic: 'economics' },
	{ id: 'F3', position: '2.4', topic: 'finances' },
	{ id: 'I3', position: '2.5', topic: 'investments' },
	{ id: 'F4', position: '2.6', topic: 'finances' },
	{ id: 'I4', position: '3.1', topic: 'investments' },
	{ id: 'E4', position: '3.2', topic: 'economics' },
	{ id: 'I5', position: '3.3', topic: 'investments' },
	{ id: 'E5', position: '3.4', topic: 'economics' },
	{ id: 'F5', position: '3.5', topic: 'finances' },
	{ id: 'E6', position: '3.6', topic: 'economics' },
	{ id: 'F6', position: '4.1', topic: 'finances' },
	{ id: 'I6', position: '4.2', topic: 'investments' },
	{ id: 'F7', position: '4.3', topic: 'finances' },
	{ id: 'I7', position: '4.4', topic: 'investments' },
	{ id: 'F8', position: '4.5', topic: 'finances' },
	{ id: 'I8', position: '4.6', topic: 'investments' },
	{ id: 'E7', position: '5.1', topic: 'economics' },
	{ id: 'F9', position: '5.2', topic: 'finances' },
	{ id: 'E8', position: '5.3', topic: 'economics' },
	{ id: 'F10', position: '5.4', topic: 'finances' },
	{ id: 'E9', position: '5.5', topic: 'economics' },
	{ id: 'F11', position: '5.6', topic: 'finances' },
	{ id: 'I9', position: '6.1', topic: 'investments' },
	{ id: 'AR2', position: '6.2', topic: 'arcade' },
	{ id: 'I10', position: '6.3', topic: 'investments' },
	{ id: 'E10', position: '6.4', topic: 'economics' },
	{ id: 'I11', position: '6.5', topic: 'investments' },
	{ id: 'FINISH', position: '6.6', topic: 'finish' },
];
