import { useContext, useState } from 'react';
import generateGridState, { Grid, GridState } from './generateGrid';
import { isEmptyCoordPossibleToMove, isRightCoordPosition } from './gridFinder';
import { getMovedGrid } from './gridMove';
import { Tiles } from './Tiles';
import { AppContext } from '../../storeContext';
import { ArcadeBase } from '../ArcadeBase';

const PuzzleGame = () => {
	const { setIsShowArcade2, theme } = useContext(AppContext);
	const [gridState, setGridState] = useState<GridState>(generateGridState());

	const doMove = (fromCoord: number[]) => {
		const toCoord = gridState?.grid.find((item) => !item.value)?.coord;
		let grid = isEmptyCoordPossibleToMove(fromCoord, toCoord) && getMovedGrid(gridState?.grid, fromCoord, toCoord);

		if (!grid) {
			return;
		}

		grid = grid.map((item) => {
			item.done = isRightCoordPosition(item.coord, item.value);

			return item;
		});

		let gridDone = (grid as Grid).filter((item) => item.done);

		// setStarted(true);
		// setMoves((m) => m + 1);
		if (typeof grid !== 'boolean' && typeof grid !== 'undefined') {
			// @ts-ignore
			setGridState((state) => ({ ...state, win: gridDone.length === grid?.length, grid }));
		}
	};

	return (
		<ArcadeBase
			startTime={600}
			defaultIsShowDescription={true}
			setIsShowArcade={setIsShowArcade2}
			name={theme.arcade2.name}
			desc={theme.arcade2.desc}
			winCond={gridState.win}
			contentStyle={theme.arcade2.contentStyle}
		>
			<>
				<div
					className="puzzle-title"
					style={{
						position: 'absolute',
						fontFamily: 'Gilroy',
						fontSize: '1.67rem',
						top: '-29%',
						left: '23%',
						color: 'white',
						width: '57%',
					}}
				>
					{gridState.win ? 'Ура! Вы выиграли!' : theme.arcade2.desc}
				</div>
				<Tiles tiles={gridState.grid} onTileClick={doMove} />
			</>
		</ArcadeBase>
	);
};

export default PuzzleGame;
