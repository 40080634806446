import React, { CSSProperties, Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { getPosition, ICard, Nav, Topic } from '../helpers';
import { AppContext } from '../storeContext';

const inactiveCardStyle: CSSProperties = { opacity: 0.5, filter: 'grayscale(0.8)', pointerEvents: 'none' };

const castStyle: Record<number, CSSProperties> = {
	1: { top: '3%', left: '3%', ...inactiveCardStyle }, // левый верхний угол
	2: { top: '3%', left: '37%' }, // вверху
	3: { top: '3%', left: '70%', ...inactiveCardStyle }, // правый верхний угол
	4: { top: '37%', left: '3%' }, // слева
	5: { top: '37%', left: '37%', ...inactiveCardStyle }, // центр
	6: { top: '37%', left: '70%' }, // справа
	7: { bottom: '3%', left: '3%', ...inactiveCardStyle }, // левый нижний угол
	8: { bottom: '3%', left: '37%' }, // внизу
	9: { bottom: '3%', left: '70%', ...inactiveCardStyle }, // правый нижний угол
};

const castArcadeCardStyle: Record<number, CSSProperties> = {
	1: { top: '-5%', left: '1%', ...inactiveCardStyle },
	2: { top: '-5%', left: '33%' },
	3: { top: '-5%', left: '65%', ...inactiveCardStyle },
	4: { top: '31%', left: '1%' },
	5: { top: '31%', left: '33%', ...inactiveCardStyle },
	6: { top: '31%', left: '65%' },
	7: { top: '62%', left: '1%', ...inactiveCardStyle },
	8: { top: '62%', left: '33%' },
	9: { top: '62%', left: '65%', ...inactiveCardStyle },
};

interface Props {
	currentPosition: string;
	topic: Topic;
	/**
	 * Позиционирование карточки в сетке карты главного экрана: 1, 2, 3, 4, 5, 6, 7, 8, 9
	 */
	cardPosition: number;
	closeCard?: boolean;
	setCurrentPosition: Dispatch<SetStateAction<string>>;
	setIsShowQuiz: Dispatch<SetStateAction<boolean>>;
	result: 'success' | 'fail' | null;
}

export const Card = ({
	currentPosition,
	topic,
	cardPosition,
	setCurrentPosition,
	setIsShowQuiz,
	result,
}: Props): JSX.Element => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const { theme, setIsShowArcade1, setIsShowArcade2, isQuestTinkoff } = useContext(AppContext);

	const goWhere: Nav | string =
		cardPosition === 2
			? 'up'
			: cardPosition === 4
			? 'left'
			: cardPosition === 6
			? 'right'
			: cardPosition === 8
			? 'down'
			: '';

	const getWidth = (topic: Topic): CSSProperties['width'] => {
		switch (topic) {
			case 'chemistry': {
				return '26.25%';
			}
			case 'history': {
				return '27%';
			}
			case 'secret': {
				return '26.85%';
			}
			case 'biology': {
				return '28.73%';
			}
			case 'physics':
			case 'astronomy':
			case 'economics':
			case 'investments':
			case 'finances': {
				return '28%';
			}
			case 'arcade': {
				return '40%';
			}
			case 'finish': {
				return '33%';
			}
			default:
				return '';
		}
	};

	const style: CSSProperties = {
		position: 'absolute',
		width: getWidth(topic),
		height: getWidth(topic),
		cursor: 'pointer',
		...(topic === 'arcade' || topic === 'finish'
			? isQuestTinkoff && topic === 'arcade'
				? {
						...castArcadeCardStyle[cardPosition],
						transform: 'scale(0.6)',
						height: 'auto',
						top: castArcadeCardStyle[cardPosition].top
							? parseInt(castArcadeCardStyle[cardPosition].top as string) - 6 + '%'
							: undefined,
						left:
							castArcadeCardStyle[cardPosition].left && [1, 4, 7].includes(cardPosition)
								? parseInt(castArcadeCardStyle[cardPosition].left as string) - 5 + '%'
								: castArcadeCardStyle[cardPosition].left,
				  }
				: isQuestTinkoff && topic === 'finish' && (cardPosition === 8 || cardPosition === 9)
				? { ...castArcadeCardStyle[cardPosition], top: '68%' }
				: castArcadeCardStyle[cardPosition]
			: castStyle[cardPosition]),
	};

	useEffect(() => {
		setIsOpen(false);
	}, [currentPosition]);

	const onCardClick = (e: React.SyntheticEvent<HTMLDivElement, MouseEvent | TouchEvent>) => {
		if (!isOpen && goWhere) {
			setIsOpen(true);
		} else if (isOpen && goWhere) {
			setCurrentPosition(getPosition(currentPosition, goWhere as Nav));
			if (!result) setIsShowQuiz(true);
		} else if (result && goWhere) {
			setCurrentPosition(getPosition(currentPosition, goWhere as Nav));
		}
	};

	const onCardHover = (e: React.SyntheticEvent<HTMLDivElement, MouseEvent | TouchEvent>) => {
		if (goWhere && !result) {
			if (e.type === 'mouseenter') {
				setIsOpen(true);
			} else if (e.type === 'mouseleave') {
				setIsOpen(false);
			}
		}
	};

	const onArcadeClick = () => {
		const nextPos = getPosition(currentPosition, goWhere as Nav);
		if (!result) {
			if (theme.cards.find((c: ICard) => c.position === nextPos)?.id === 'AR2') {
				setIsShowArcade2(true);
			} else {
				setIsShowArcade1(true);
			}
		}
		setCurrentPosition(getPosition(currentPosition, goWhere as Nav));
	};

	if (topic === 'arcade') {
		return (
			<img
				src={theme.card.arcade}
				data-position={cardPosition}
				style={style}
				alt="Card arcade"
				onClick={onArcadeClick}
			/>
		);
	}

	if (topic === 'finish') {
		return (
			<div
				onClick={() => goWhere && setCurrentPosition('6.6')}
				style={{
					...style,
					height: 0,
					paddingBottom: '30%',
					cursor: 'pointer',
					backgroundImage: `url(${theme.card.finish})`,
					backgroundSize: 'cover',
				}}
				data-map-pos={cardPosition}
				className={`card card-${topic}`}
			></div>
		);
	}

	return (
		<div
			className={`card card-${topic}`}
			style={style}
			onClick={onCardClick}
			onMouseEnter={onCardHover}
			onMouseLeave={onCardHover}
		>
			{!result ? (
				<>
					<div className="front">
						<img src={theme.card[topic].front} alt={`Card ${topic} Frontside`} />
					</div>
					<div className={'back'}>
						<img src={theme.card[topic].back} alt={`Card ${topic} Backside`} />
					</div>
				</>
			) : (
				<div className="result">
					<img
						src={result === 'success' ? theme.card[topic].success : theme.card[topic].fail}
						alt={`Card ${topic} Result`}
					/>
				</div>
			)}
		</div>
	);
};
