import qs from 'qs';
import cookie from 'cookie';
import React, { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { ICard } from './helpers';
import { fetchGameBegin, fetchGameEnd } from './api';
import './assets/fonts/Gilroy/stylesheet.css';
// import { questions as questionsArray1 } from './questions';
import { getTheme } from './theme';

type TContext = {
	gameStop: boolean;
	// cards: ICard[];
	currentPosition: string;
	points: number;
	results: Record<string, 'success' | 'fail' | null>;
	// questions: Record<string, Question>;
	qid: string;
	tokenPub: string;
	cardProps: ICard | null;
	isShowStartScreen: boolean;
	isShowQuiz: boolean;
	isShowArcade1: boolean;
	isShowArcade2: boolean;
	isApiError: boolean;
	theme: Record<string, string | number | any>;
	setIsShowStartScreen: Dispatch<SetStateAction<boolean>>;
	setIsShowQuiz: Dispatch<SetStateAction<boolean>>;
	setIsShowArcade1: Dispatch<SetStateAction<boolean>>;
	setIsShowArcade2: Dispatch<SetStateAction<boolean>>;
	setCurrentPosition: Dispatch<SetStateAction<string>>;
	setPoints: Dispatch<SetStateAction<number>>;
	setResults: Dispatch<SetStateAction<Record<string, 'success' | 'fail' | null>>>;
	setCardProps: Dispatch<SetStateAction<ICard | null>>;
	setQid: Dispatch<SetStateAction<string>>;
	setTokenPub: Dispatch<SetStateAction<string>>;
	setIsApiError: Dispatch<SetStateAction<boolean>>;
	isQuestCosmic: boolean;
	isQuestPhysics: boolean;
	isQuestTinkoff: boolean;
};

export const defaultContext: TContext = {
	currentPosition: '1.1',
	// cards: [],
	points: 8,
	// questions: {},
	results: {},
	gameStop: false,
	qid: '32',
	tokenPub: '',
	cardProps: null,
	isShowStartScreen: true,
	isShowQuiz: false,
	isShowArcade1: false,
	isShowArcade2: false,
	isApiError: false,
	theme: {},
	setCurrentPosition: () => {},
	setCardProps: () => {},
	setQid: () => {},
	setTokenPub: () => {},
	setIsShowStartScreen: () => {},
	setIsShowQuiz: () => {},
	setIsShowArcade1: () => {},
	setIsShowArcade2: () => {},
	setIsApiError: () => {},
	setPoints: () => {},
	setResults: () => {},
	isQuestCosmic: false,
	isQuestPhysics: false,
	isQuestTinkoff: false,
};

export const AppContext = createContext<TContext>(defaultContext);

const AppContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [isShowStartScreen, setIsShowStartScreen] = useState<boolean>(true);
	const [currentPosition, setCurrentPosition] = useState<string>('1.1');
	const [points, setPoints] = useState<number>(8);
	const [results, setResults] = useState<Record<string, 'success' | 'fail' | null>>({});
	const [cardProps, setCardProps] = useState<ICard | null>(null);
	const [qid, setQid] = useState<string>('32');
	const [gameStop, setGameStop] = useState<boolean>(false);
	const [isShowQuiz, setIsShowQuiz] = useState<boolean>(false);
	const [isShowArcade1, setIsShowArcade1] = useState<boolean>(false);
	const [isShowArcade2, setIsShowArcade2] = useState<boolean>(false);
	const [tokenPub, setTokenPub] = useState<string>('');
	const [isApiError, setIsApiError] = useState<boolean>(false);
	const [theme, setTheme] = useState<Record<string, string | number | any>>(getTheme(qid));

	const isQuestCosmic = qid === '21' || qid === '22';
	const isQuestPhysics = qid === '32' || qid === '33';
	const isQuestTinkoff = qid === '34';

	useEffect(() => {
		setTheme(getTheme(qid));
	}, [qid]);

	useEffect(() => {
		if (currentPosition === '6.6' || !points) {
			setGameStop(true);
		}
	}, [currentPosition, points]);

	useEffect(() => {
		// eslint-disable-next-line no-restricted-globals
		const qidParsed = qs.parse(location.search, { ignoreQueryPrefix: true });
		const tokenPub = cookie.parse(document.cookie)?.['token'];
		if (Object.keys(qidParsed).length > 0) {
			setQid(qidParsed.qid as string);
		}
		if (tokenPub) {
			setTokenPub(tokenPub);
		}
	}, []);

	useEffect(() => {
		if (tokenPub && qid) {
			fetchGameBegin(tokenPub, qid)
				.then((res) => {
					if (res !== 'ok') {
						setIsApiError(true);
					}
				})
				.catch(() => setIsApiError(true));
		}
	}, [tokenPub, qid]);

	useEffect(() => {
		if (gameStop && qid && tokenPub) {
			fetchGameEnd(tokenPub, qid, points)
				.then((res) => {
					if (res !== 'ok') {
						setIsApiError(true);
					}
				})
				.catch(() => setIsApiError(true));
		}
	}, [gameStop, qid, tokenPub, points]);

	useEffect(() => {
		const currentCard = theme.cards.find((c: ICard) => c.position === currentPosition) || null;
		setCardProps(currentCard);
	}, [currentPosition, theme.cards]);

	useEffect(() => {
		setPoints(
			Object.entries(results)?.reduce(
				(acc, [k, v]) =>
					!(theme.cards.find((c: ICard) => c.position === k)?.topic === 'arcade') && v === 'fail'
						? acc - 1
						: theme.cards.find((c: ICard) => c.position === k)?.topic === 'arcade' && v === 'success'
						? acc + 1
						: acc,
				8
			)
		);
	}, [results, theme.cards]);

	return (
		<AppContext.Provider
			value={{
				currentPosition,
				points,
				results,
				cardProps,
				gameStop,
				qid,
				tokenPub,
				isApiError,
				isShowStartScreen,
				isShowArcade1,
				isShowArcade2,
				isShowQuiz,
				theme,
				setIsShowStartScreen,
				setCurrentPosition,
				setPoints,
				setIsShowQuiz,
				setIsShowArcade1,
				setIsShowArcade2,
				setResults,
				setCardProps,
				setQid,
				setTokenPub,
				setIsApiError,
				isQuestCosmic,
				isQuestPhysics,
				isQuestTinkoff,
			}}
		>
			{children}
		</AppContext.Provider>
	);
};

export default AppContextProvider;
