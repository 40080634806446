import { CSSProperties, useContext, useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { QuizVariantBtn } from './QuizVariantBtn';
import 'react-circular-progressbar/dist/styles.css';
import parse from 'html-react-parser';
import { cardNames } from '../helpers';
import { AppContext } from '../storeContext';

// const SVGCircle = (styles: CSSProperties): JSX.Element => <div className="Main__Container1__quiz__timer__circle"></div>;
type StrokeLinecap = 'round' | 'butt' | undefined;

export const Quiz = () => {
	const { cardProps, currentPosition, setResults, setIsShowQuiz, theme, isQuestCosmic, isQuestPhysics } =
		useContext(AppContext);
	const [timeLeft, setTimeLeft] = useState<number>(30);
	const [selectedVar, setSelectedVar] = useState<string | null>(null);
	const {
		rightVar = '',
		question = '',
		var1 = '',
		var2 = '',
		var3 = '',
		var4 = '',
	} = theme.questions && cardProps?.topic && cardProps?.topic !== 'arcade' && cardProps?.id
		? theme.questions[cardProps.topic][cardProps.id]
		: {};
	const topicName = cardProps?.topic ? cardNames[cardProps?.topic]?.toUpperCase() : '';
	const forwardIcon = (
		<svg width="100%" height="100%" viewBox="0 0 99 99" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="49.5" cy="49.5" r="49.5" fill={theme.themeColor} />
			<path
				d="M19.8 46.9107V59.043H53.406L48.9289 75.4683L79.2 49.7664L48.9289 21.7799L53.406 42.0466L19.8 46.9107Z"
				fill="white"
			/>
		</svg>
	);
	const progressBarStyles = {
		text: { fill: 'white', fontSize: '2.8rem' },
		trail: { stroke: 'transparent' },
		path: { stroke: theme.themeColorLight, strokeLinecap: 'round' as StrokeLinecap },
	};

	const result = !timeLeft ? 'fail' : !selectedVar ? null : selectedVar === rightVar ? 'success' : 'fail';

	const quizStyle: CSSProperties = {
		position: 'absolute',
		width: '100%',
		top: 0,
		bottom: 0,
		left: 0,
		background: `${theme.themeBgColor}`,
	};

	useEffect(() => {
		if (result) {
			setResults((res) => ({
				...res,
				[currentPosition]: result,
			}));
		}
	}, [selectedVar, currentPosition, result, setResults]);

	useEffect(() => {
		let timer: string | number | NodeJS.Timeout | undefined;
		if (!timer) {
			timer = setInterval(() => {
				setTimeLeft((v) => v - 1);
			}, 1000);
		}
		if (timeLeft === 0) {
			clearInterval(timer);
		}
		return () => clearInterval(timer);
	}, [timeLeft]);

	const variantsMaxWidth = [var1, var2, var3, var4].some((variant) => variant.length > 60)
		? '65%'
		: [var1, var2, var3, var4].some((variant) => variant.length > 35)
		? '60%'
		: [var1, var2, var3, var4].some((variant) => variant.length > 25)
		? '55%'
		: [var1, var2, var3, var4].some((variant) => variant.length > 20)
		? '50%'
		: '45%';

	return (
		<div className="quiz" style={quizStyle}>
			<div className="progressBarContainer">
				{!result ? (
					<>
						<CircularProgressbar
							minValue={0}
							maxValue={30}
							value={timeLeft}
							text={`${timeLeft}`}
							strokeWidth={6}
							styles={progressBarStyles}
							className="timer"
						/>
						<svg
							style={{ position: 'absolute', top: '-3px', left: '50%', transform: 'translateX(-50%)' }}
							width="10"
							height="10"
							viewBox="0 0 14 14"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<circle
								cx="6.71019"
								cy="7.03099"
								r="6"
								fill={isQuestCosmic ? '#57C1FA' : isQuestPhysics ? '#FFA718' : theme.arrowColor}
							/>
						</svg>
					</>
				) : (
					<button
						onClick={() => setIsShowQuiz(false)}
						style={{ padding: 0, margin: 0, background: 'none', border: 'none', cursor: 'pointer' }}
					>
						{forwardIcon}
					</button>
				)}
			</div>
			<h3
				className="topicName"
				style={{
					display: 'block',
					fontFamily: 'Gilroy',
					color: theme.themeColorLight,
					fontSize: '2.8rem',
					lineHeight: 1,
					marginTop: '8.5%',
					marginBottom: '25px',
				}}
			>
				{topicName}
			</h3>
			<p
				className="question"
				style={
					(parse(question) as string).length > 40 && (parse(question) as string).length < 130
						? { fontSize: `${3.8 + 0.025 * (40 - (parse(question) as string).length)}rem` }
						: (parse(question) as string).length > 131
						? { fontSize: `${4 + 0.015 * (40 - (parse(question) as string).length)}rem` }
						: { fontSize: '2.8rem' }
				}
			>
				{`${parse(question)}`}
			</p>
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div className="variants" style={{ maxWidth: variantsMaxWidth }}>
					<QuizVariantBtn
						id="var1"
						text={var1}
						selectedVar={selectedVar}
						rightVariant={rightVar}
						setIsSelected={setSelectedVar}
						showRightVar={!timeLeft || result === 'fail'}
					/>
					<QuizVariantBtn
						id="var2"
						text={var2}
						selectedVar={selectedVar}
						rightVariant={rightVar}
						setIsSelected={setSelectedVar}
						showRightVar={!timeLeft || result === 'fail'}
					/>
					<QuizVariantBtn
						id="var3"
						text={var3}
						selectedVar={selectedVar}
						rightVariant={rightVar}
						setIsSelected={setSelectedVar}
						showRightVar={!timeLeft || result === 'fail'}
					/>
					<QuizVariantBtn
						id="var4"
						text={var4}
						selectedVar={selectedVar}
						rightVariant={rightVar}
						setIsSelected={setSelectedVar}
						showRightVar={!timeLeft || result === 'fail'}
					/>
				</div>
			</div>
		</div>
	);
};
