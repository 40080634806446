import { MutableRefObject, useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../storeContext';
import { ArcadeBase } from '../ArcadeBase';
import light from '../../assets/light.png';
import './styleN.scss';

const HanoiTower = () => {
	const { setIsShowArcade1, theme } = useContext(AppContext);
	const [win, setWin] = useState<boolean>(false);
	const discOneRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
	const discTwoRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
	const discThreeRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
	const discFourRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
	const discFiveRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
	const discSixRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

	useEffect(() => {
		let timer: string | number | NodeJS.Timeout | undefined;

		if (!timer) {
			timer = setInterval(() => {
				const order1 = discOneRef?.current && getComputedStyle(discOneRef?.current).order;
				const order2 = discTwoRef?.current && getComputedStyle(discTwoRef?.current).order;
				const order3 = discThreeRef?.current && getComputedStyle(discThreeRef?.current).order;
				const order4 = discFourRef?.current && getComputedStyle(discFourRef?.current).order;
				const order5 = discFiveRef?.current && getComputedStyle(discFiveRef?.current).order;
				const order6 = discSixRef?.current && getComputedStyle(discSixRef?.current).order;
				setWin(!![order1, order2, order3, order4, order5, order6].every((el) => el === '9'));
			}, 1000);
		}
		return () => clearInterval(timer);
	}, []);

	return (
		<ArcadeBase
			startTime={300}
			defaultIsShowDescription={false}
			setIsShowArcade={setIsShowArcade1}
			name={theme.arcade1.name}
			desc={theme.arcade1.desc}
			winCond={win}
			contentStyle={theme.arcade1.contentStyle}
		>
			<>
				<div className="Hanoi" style={{ height: '100%' }}>
					<div className="hanoi-title">{win ? 'Ура! Вы выиграли!' : theme.arcade1.desc}</div>
					<div
						className="contend"
						style={{
							background: `url(${light}) no-repeat 50% 100%/100%`,
						}}
					>
						<form>
							<div className="discs">
								<input id="one" type="text" tabIndex={-1} readOnly />
								<input name="one" type="radio" tabIndex={-1} defaultChecked />
								<input name="one" type="radio" tabIndex={-1} />
								<input name="one" type="radio" tabIndex={-1} />
								<label htmlFor="one"></label>
								<div ref={discOneRef} className="disc one"></div>

								<input id="two" type="text" tabIndex={-1} readOnly />
								<input name="two" type="radio" tabIndex={-1} defaultChecked />
								<input name="two" type="radio" tabIndex={-1} />
								<input name="two" type="radio" tabIndex={-1} />
								<label htmlFor="two"></label>
								<div ref={discTwoRef} className="disc two"></div>

								<input id="three" type="text" tabIndex={-1} readOnly />
								<input name="three" type="radio" tabIndex={-1} defaultChecked />
								<input name="three" type="radio" tabIndex={-1} />
								<input name="three" type="radio" tabIndex={-1} />
								<label htmlFor="three"></label>
								<div ref={discThreeRef} className="disc three"></div>

								<input id="four" type="text" tabIndex={-1} readOnly />
								<input name="four" type="radio" tabIndex={-1} defaultChecked />
								<input name="four" type="radio" tabIndex={-1} />
								<input name="four" type="radio" tabIndex={-1} />
								<label htmlFor="four"></label>
								<div ref={discFourRef} className="disc four"></div>

								<input id="five" type="text" tabIndex={-1} readOnly />
								<input name="five" type="radio" tabIndex={-1} defaultChecked />
								<input name="five" type="radio" tabIndex={-1} />
								<input name="five" type="radio" tabIndex={-1} />
								<label htmlFor="five"></label>
								<div ref={discFiveRef} className="disc five"></div>

								<input id="six" type="text" tabIndex={-1} readOnly />
								<input name="six" type="radio" tabIndex={-1} defaultChecked />
								<input name="six" type="radio" tabIndex={-1} />
								<input name="six" type="radio" tabIndex={-1} />
								<label htmlFor="six"></label>
								<div ref={discSixRef} className="disc six"></div>

								<input id="zero" type="text" tabIndex={-1} readOnly />

								<div className="spacer a"></div>
								<div className="separator ab"></div>
								<div className="spacer b"></div>
								<div className="separator bc"></div>
								<div className="spacer c"></div>

								<div className="tower a"></div>
								<div className="tower b"></div>
								<div className="tower c"></div>
							</div>
						</form>
					</div>
				</div>
			</>
		</ArcadeBase>
	);
};

export default HanoiTower;
