import { isRightCoordPosition } from './gridFinder.js';

export type Grid = { coord: number[]; value: number; done: boolean }[];

export const generateGrid = (rMax = 4, cMax = 4) => {
	let size = rMax * cMax;
	let plain = new Array(size).fill(0).map((_, i) => i);

	let grid = [] as Grid;

	for (let r = 0; r < rMax; r++) {
		for (let c = 0; c < cMax; c++) {
			let item = {
				coord: [r, c],
				value: plain.splice(getRandomInt(0, plain.length), 1)[0],
			} as Grid[number];

			item.done = isRightCoordPosition(item.coord, item.value);

			grid.push(item);
		}
	}

	return grid;
};

function getRandomInt(min: number, max: number) {
	return Math.floor(Math.random() * (max - min)) + min;
}

export type GridState = {
	grid: Grid;
	win: boolean;
	emptyCoord: number[] | undefined;
};

// подходит только для матриц с четным числом строк
const isSolvable = (grid: Grid): boolean => {
	const inversions = grid
		.filter((el) => !!el.value)
		.reduce((acc, n, i) => {
			const currValue = n.value;
			const nextValue = grid[i + 1]?.value;
			if (currValue > nextValue) {
				acc++;
			}
			return acc;
		}, 0);
	const isEven = (num: number) => num % 2 === 0;
	const emptyCellRow = grid.find((item) => !item.value)?.coord[0];
	return isEven(inversions) ? emptyCellRow === 0 || emptyCellRow === 2 : emptyCellRow === 1 || emptyCellRow === 3;
};

const castSolvable = (a: number, b: number) => {
	let generated = generateGrid(a, b);
	// let attempts = 1;
	while (!isSolvable(generated)) {
		// console.log('attempts: ', attempts);
		// attempts++;
		generated = generateGrid(a, b);
	}
	// console.log(generated);
	return generated;
};

const generatedSolvableGrid = castSolvable(4, 4);

const grid = (rMax = 4, cMax = 4) => {
	let state = {} as GridState;
	state.grid = generatedSolvableGrid;
	state.win = state.grid.filter((item) => item.done).length === state.grid.length;
	state.emptyCoord = state.grid.find((item) => !item.value)?.coord;

	return state;
};

export default grid;
