import { CardIcon } from './Memory';
import crystal from './../assets/crystal-card.png';
import coin from './../assets/card_shirt.svg';
import { useContext } from 'react';
import { AppContext } from '../storeContext';

interface Props {
	card: CardIcon;
	onClick: (num: number) => void;
	index: number;
	isInactive: boolean;
	isFlipped: boolean;
	isDisabled: boolean;
}

export const CardMemory = ({ onClick, card, index, isInactive, isFlipped, isDisabled }: Props) => {
	const { isQuestTinkoff } = useContext(AppContext);

	const handleClick = () => {
		!isFlipped && !isDisabled && onClick(index);
	};

	const cardClass = isFlipped ? 'card is-flipped' : isInactive ? 'card is-inactive' : 'card';

	return (
		<div className={cardClass} onClick={handleClick}>
			<div className="card-face card-font-face">
				<img src={isQuestTinkoff ? coin : crystal} alt="fontface crystal"></img>
			</div>
			<div className="card-face card-back-face">
				<img src={card.img} alt={`backface ${card.type}`} />
			</div>
		</div>
	);
};
