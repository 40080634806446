import crystal from './../assets/crystal-life.png';
import proton from './../assets/proton.png';
import coin from './../assets/Coin.png';

export const Crystals = ({ crystalsNum }: { crystalsNum: number }): JSX.Element => {
	const getStyle = (index: number) => {
		switch (index) {
			case 0: {
				return { transform: 'scale(2.0)' };
			}
			case 1: {
				return { transform: 'scale(2.0) scaleX(-1) rotate(180deg)', marginTop: '10px' };
			}
			case 2: {
				return { transform: 'scale(2.0) scaleX(-1) rotate(-30deg)' };
			}
			case 3: {
				return { transform: 'scale(2.0) rotate(200deg)' };
			}
			case 4: {
				return { transform: 'scale(2.0) rotate(25deg)' };
			}
			case 5: {
				return { transform: 'scale(2.0) scaleX(-1) rotate(160deg)' };
			}
			case 6: {
				return { transform: 'scale(2.0) scaleX(-1)' };
			}
			case 7: {
				return { transform: 'scale(2.0) rotate(180deg)' };
			}
			case 8: {
				return { transform: 'scale(2.0)' };
			}
			case 9: {
				return { transform: 'scale(2.0) scaleX(-1) rotate(180deg)', marginTop: '10px' };
			}
		}
	};

	return (
		<>
			{Array(crystalsNum)
				.fill('')
				.map((_, i) => (
					<img
						key={i}
						src={crystal}
						alt="Life Crystal"
						style={{ ...getStyle(i), width: '25%', marginBottom: '18px' }}
					/>
				))}
		</>
	);
};

export const Protons = ({ num }: { num: number }): JSX.Element => {
	const getStyle = (index: number) => {
		switch (index) {
			case 0: {
				return {};
			}
			case 1: {
				return { rotate: '-14.75deg', paddingBottom: '10px', paddingLeft: '5px' };
			}
			case 2: {
				return { rotate: '15deg', paddingBottom: '7px', marginLeft: '-5px' };
			}
			case 3: {
				return { rotate: '-17.66deg', paddingBottom: '5px' };
			}
			case 4: {
				return { paddingTop: '20px' };
			}
			case 5: {
				return { marginLeft: '-4px' };
			}
			case 6: {
				return { paddingBottom: '10px' };
			}
			case 7: {
				return { rotate: '15deg', paddingLeft: '4px' };
			}
			case 8: {
				return {};
			}
			case 9: {
				return { rotate: '-14.75deg', paddingBottom: '10px', paddingLeft: '5px' };
			}
		}
	};

	return (
		<>
			{Array(num)
				.fill('')
				.map((_, i) => (
					<img
						key={i}
						src={proton}
						alt="Life Crystal"
						style={{ ...getStyle(i), width: '25%', transform: 'scale(1.625)' }}
					/>
				))}
		</>
	);
};

export const Coins = ({ num }: { num: number }): JSX.Element => {
	const getStyle = (index: number) => {
		switch (index) {
			case 0: {
				return {};
			}
			case 1: {
				return { transform: 'scaleX(-1)' };
			}
			case 2: {
				return {};
			}
			case 3: {
				return { transform: 'scaleX(-1)' };
			}
			case 4: {
				return {};
			}
			case 5: {
				return {};
			}
			case 6: {
				return { transform: 'scaleX(-1)' };
			}
			case 7: {
				return { transform: 'scaleX(-1)' };
			}
			case 8: {
				return {};
			}
			case 9: {
				return { transform: 'scaleX(-1)' };
			}
		}
	};

	return (
		<>
			{Array(num)
				.fill('')
				.map((_, i) => (
					<img
						key={i}
						src={coin}
						alt="Life Coin"
						style={{
							...getStyle(i),
							width: '25%',
							transform: `scale(1.3) ${getStyle(i)?.transform ?? ''}`,
						}}
					/>
				))}
		</>
	);
};
