import { useContext } from 'react';
import { Nav } from '../helpers';
import { AppContext } from '../storeContext';

export const Arrow = ({ nav }: { nav: Nav }) => {
	const { theme, isQuestTinkoff } = useContext(AppContext);
	return (
		<div className={`arrow arrow-${nav}`}>
			<svg width="74" height="79" viewBox="0 0 74 79" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g filter="url(#filter0_d_63_739)">
					<path
						d="M6 42.734C6.05 36.5501 6.2 32.1259 7 27.7016C8.05 21.92 10.55 16.8421 13.85 12.0157C17.3138 6.87442 22.4635 3.11985 28.4 1.40757C33.3 -0.100692 38.3 -0.301794 43.4 0.351788C48.6172 0.985443 53.5891 2.94018 57.85 6.03292C60.1761 7.70781 62.1336 9.8472 63.6 12.3174C64.0319 13.0783 64.5708 13.7726 65.2 14.3786C66.4696 15.6391 67.4136 17.1924 67.95 18.9034C68.4 20.1603 69.55 21.1156 70.25 22.3222C71.5698 24.7101 72.3868 27.3456 72.65 30.0646C72.8662 32.0012 72.9831 33.9478 73 35.8966C73.05 41.5274 71.65 46.8063 69.5 51.9344C67.7 56.2581 65.4 60.2299 62 63.5481C57.5145 67.9562 51.8207 70.9151 45.65 72.0446C40.65 72.9496 35.6 73.4021 30.6 72.4468C19.85 70.4358 10.05 64.2016 6.9 51.4317C6.05 48.0129 6.05 44.4937 6 42.734Z"
						fill={isQuestTinkoff ? theme.arrowColor : '#FDFDFD'}
					/>
					<path
						d="M50.1997 40.5221C49.6497 40.1701 49.1997 40.321 48.7497 40.321C42.2997 40.3712 35.7997 40.4215 29.3497 40.4215C28.3073 40.4735 27.2629 40.3719 26.2497 40.1199C25.7512 40.025 25.3022 39.756 24.9819 39.3604C24.6617 38.9648 24.4909 38.468 24.4997 37.958C24.4997 36.8519 25.1997 36.1481 26.2497 35.8464C27.2119 35.5945 28.2068 35.4927 29.1997 35.5448L48.7497 35.6956C49.2505 35.7185 49.749 35.6147 50.1997 35.394L47.3997 32.8299C45.8497 31.3216 44.1997 29.9139 42.8497 28.2046C41.4997 26.4952 41.4997 25.54 42.1997 24.7356C42.8997 23.9312 44.3997 24.0317 45.7997 25.188C49.4997 28.2046 52.4497 31.9249 55.4997 35.5448L55.8497 36.0475C57.5497 38.1088 57.4997 38.7624 55.9997 40.9243C52.4984 46.1196 48.1942 50.7191 43.2497 54.5489C42.5568 55.144 41.76 55.6043 40.8997 55.9064C40.0497 56.0572 39.1997 56.0572 38.5997 55.3533C38.3361 55.0265 38.1684 54.6322 38.1154 54.2148C38.0624 53.7973 38.1262 53.3733 38.2997 52.9904C38.7511 51.9018 39.4957 50.9616 40.4497 50.2755C43.3997 47.7617 46.3497 45.2982 48.7997 42.2314L50.1997 40.5221Z"
						fill={isQuestTinkoff ? 'black' : theme.arrowColor}
					/>
				</g>
				<defs>
					<filter
						id="filter0_d_63_739"
						x="0"
						y="-0.000976562"
						width="73.0012"
						height="78.9798"
						filterUnits="userSpaceOnUse"
						colorInterpolationFilters="sRGB"
					>
						<feFlood floodOpacity="0" result="BackgroundImageFix" />
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset dx="-5" dy="5" />
						<feGaussianBlur stdDeviation="0.5" />
						<feComposite in2="hardAlpha" operator="out" />
						<feColorMatrix type="matrix" values="0 0 0 0 0.0832292 0 0 0 0 0.108274 0 0 0 0 0.141667 0 0 0 0.7 0" />
						<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_63_739" />
						<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_63_739" result="shape" />
					</filter>
				</defs>
			</svg>
		</div>
	);
};
