import { AppContext } from '../storeContext';
import { CSSProperties, useContext } from 'react';
import { StartFinishScreen } from './StartFinishScreen';
import { Map } from './Map';
import { Quiz } from './Quiz';
import { Minimap } from './Minimap';
import { Coins, Crystals, Protons } from './Crystals';
import './App.sass';

const App = (): JSX.Element => {
	const {
		currentPosition,
		cardProps,
		isApiError,
		isShowStartScreen,
		gameStop,
		points,
		isShowQuiz,
		isShowArcade1,
		isShowArcade2,
		theme,
		isQuestCosmic,
		isQuestPhysics,
	} = useContext(AppContext);

	const appStyle: CSSProperties = {
		display: 'flex',
		textAlign: 'center',
		width: '100vw',
		background: `url(${theme?.bg}) no-repeat 50% 50%/cover, black`,
		height: '100vh',
		alignItems: 'center',
		flexWrap: 'nowrap',
	};

	const mainStyle: CSSProperties = {
		display: 'flex',
		flexWrap: 'nowrap',
		width: '100%',
		...(isShowStartScreen || gameStop ? { height: '100%' } : {}),
	};

	const cont1Style: CSSProperties = {
		position: 'relative',
		width: 'calc(50% - 16px)',
		height: 0,
		paddingBottom: 'calc(50% - 16px)',
		marginRight: 'calc((120/1920)*100%)',
		marginLeft: 'calc((200/1920)*100%)',
		border: `8px solid ${theme.themeColor}`,
	};

	const cont2Style: CSSProperties = {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		width: 'calc((490/1920)*100%)',
	};

	const renderMainScreen = () => {
		if (isShowStartScreen || gameStop) {
			return <StartFinishScreen />;
		} else {
			return (
				<>
					<div className="container1" style={cont1Style}>
						{isShowQuiz && cardProps ? (
							<Quiz />
						) : isShowArcade1 ? (
							theme.arcade1.component
						) : isShowArcade2 ? (
							theme.arcade2.component
						) : (
							<Map />
						)}
					</div>
					<div className="container2" style={cont2Style}>
						<Minimap currentPosition={currentPosition} />
						<div
							className="crystals"
							style={{
								display: 'flex',
								width: '100%',
								height: '100%',
								alignItems: 'center',
								alignContent: 'center',
								flexWrap: 'wrap',
							}}
						>
							{isQuestCosmic ? (
								<Crystals crystalsNum={points} />
							) : isQuestPhysics ? (
								<Protons num={points} />
							) : (
								<Coins num={points} />
							)}
						</div>
					</div>
				</>
			);
		}
	};

	return (
		<div className="App" style={appStyle}>
			<div className="Main" style={mainStyle}>
				{!isApiError && renderMainScreen()}
			</div>
		</div>
	);
};

export default App;
