import { Question, Topic } from './helpers';

export const questions: Record<Topic, Question> = {
	astronomy: {
		A1: {
			question:
				'На какой планете находится потухший вулкан, который теперь является самой большой горой в Солнечной системе?',
			var1: 'Венера',
			var2: 'Марс',
			var3: 'Меркурий',
			var4: 'Земля',
			rightVar: 'Марс',
		},
		A2: {
			question: 'На какой планете наблюдается северное сияние?',
			var1: 'На Венере',
			var2: 'На Юпитере',
			var3: 'На Меркурии',
			var4: 'На Марсе',
			rightVar: 'На Юпитере',
		},
		A3: {
			question: 'Где год длится около 30 земных лет?',
			var1: 'На Юпитере',
			var2: 'На Уране',
			var3: 'На Марсе',
			var4: 'На Сатурне',
			rightVar: 'На Сатурне',
		},
		A4: {
			question: 'Система, состоящая из звезд, пыли, темной материи, межзвездного газа и связанная гравитацией - это...',
			var1: 'Галактика',
			var2: 'Черная Дыра',
			var3: 'Солнечная Система',
			var4: 'Вселенная',
			rightVar: 'Галактика',
		},
		A5: {
			question: 'Звезды какого цвета живут дольше остальных?',
			var1: 'Белые',
			var2: 'Желтые',
			var3: 'Красные',
			var4: 'Голубые',
			rightVar: 'Красные',
		},
		A6: {
			question: 'Оказывается, у Солнца есть адрес. Какой?',
			var1: 'Рукав Лебедя',
			var2: 'Рукав Стрельца',
			var3: 'Рукав Ориона',
			var4: 'Рукав Центавра',
			rightVar: 'Рукав Стрельца',
		},
		A7: {
			question:
				'Две галактики , находящиеся в процессе слияния в созвездии Волосы Вероники, за счет своей формы получили название ',
			var1: 'Мышки',
			var2: 'Близнецы',
			var3: 'Цыплята',
			var4: 'Котята',
			rightVar: 'Мышки',
		},
		A8: {
			question: 'Какого телескопа не существует?',
			var1: 'Телескопа Гюйгенса',
			var2: 'Телескопа Гевелия',
			var3: 'Телескопа Кеплера',
			var4: 'Телескопа Лакайля',
			rightVar: 'Телескопа Лакайля',
		},
	},
	history: {
		H1: {
			question: 'На Венере есть кратер Сафо и кратер Клеопатры. А кто придумал эти названия?',
			var1: 'Галилео Галилей',
			var2: 'Исаак Ньютон',
			var3: 'Михаил Ломоносов',
			var4: 'Андрей Сахаров',
			rightVar: 'Михаил Ломоносов',
		},
		H2: {
			question: 'В каком метеоритном кратере в России обнаружили крупнейшее месторождение импактных алмазов?',
			var1: 'Кратер Эльгыгытгын',
			var2: 'Кратер Попигай',
			var3: 'Кратер Янисъярви',
			var4: 'Кратер Карлинский',
			rightVar: 'Кратер Попигай',
		},
		H3: {
			question: 'Какой персонаж диснеевского мультфильма был назван в честь одной из планет?',
			var1: 'Обезьянка Макемаке',
			var2: 'Змея Эрида',
			var3: 'Вислоухий песик Плуто',
			var4: 'Рысь Хаумеа',
			rightVar: 'Вислоухий песик Плуто',
		},
		H4: {
			question:
				'У многих американских президентов были собаки. А кому подарили самого необычного, «космического» щенка?',
			var1: 'Рональду Рейгану',
			var2: 'Джорджу Бушу',
			var3: 'Бараку Обаме',
			var4: 'Джону Кеннеди',
			rightVar: 'Джону Кеннеди',
		},
		H5: {
			question:
				'В космос слетали не только собаки Белка и Стрелка, но и другие животные. А кто из этих зверушек еще не путешествовал к звездам?',
			var1: 'Обезьяна',
			var2: 'Кролик',
			var3: 'Черепаха',
			var4: 'Кошка',
			rightVar: 'Кролик',
		},
		H6: {
			question:
				'Удивительно, но многие священники писали труды по астрономии. Кто из этих ученых не является служителем церкви?',
			var1: 'Джордано Бруно',
			var2: 'Николай Коперник',
			var3: 'Джузеппе Пьяцци',
			var4: 'Иоганн Кеплер',
			rightVar: 'Иоганн Кеплер',
		},
		H7: {
			question:
				'В конце ХХ века была найдена кость мамонта возрастом более 30 тысяч лет. Какое созвездие было изображено на ней?',
			var1: 'Созвездие Большого Пса',
			var2: 'Созвездие Ориона',
			var3: 'Созвездие Большой Медведицы ',
			var4: 'Созвездие Гидры',
			rightVar: 'Созвездие Ориона',
		},
		H8: {
			question:
				'Кто из русских космонавтов известен не только полетами в космос, но и своими произведениями искусства? ',
			var1: 'Юрий Гагарин',
			var2: 'Алексей Леонов',
			var3: 'Борис Волынов',
			var4: 'Валентина Терешкова',
			rightVar: 'Алексей Леонов',
		},
	},
	chemistry: {
		CH1: {
			question:
				'Этот химический элемент открыли, используя спектроскопию для изучения звезд, а в лаборатории его выделили спустя почти 30 лет',
			var1: 'Рубидий',
			var2: 'Гелий',
			var3: 'Цезий',
			var4: 'Таллий',
			rightVar: 'Гелий',
		},
		CH2: {
			question: 'После Большого взрыва сначала возникли самые легкие химические элементы:',
			var1: 'Гелий и Водород',
			var2: 'Неон и Углерод',
			var3: 'Литий и Бериллий',
			var4: 'Углерод и Водород',
			rightVar: 'Гелий и Водород',
		},
		CH3: {
			question: 'Желтый карлик, состоящий на 92% из Водорода и на 7% из гелия - это',
			var1: 'Солнце',
			var2: 'Луна',
			var3: 'Марс',
			var4: 'Астероид',
			rightVar: 'Солнце',
		},
		CH4: {
			question: 'В XIV-XIX вв. на Солнце был замечен спад активности. Этот период был назван...',
			var1: 'Малым ледниковым периодом',
			var2: 'Ледниковым периодом',
			var3: 'Глобальным потеплением',
			var4: 'Периодом охлаждения',
			rightVar: 'Малым ледниковым периодом',
		},
		CH5: {
			question: 'На какой планете после Земли самая благоприятная температура для жизни белковых организмов? ',
			var1: 'Марс',
			var2: 'Сатурн',
			var3: 'Юпитер',
			var4: 'Меркурий',
			rightVar: 'Марс',
		},
		CH6: {
			question: 'После начала процесса синтеза гелия из водорода звезда светит еще около',
			var1: '5 триллионов лет',
			var2: '7 милионов лет',
			var3: '3 миллиарда лет',
			var4: '10 миллиардов лет',
			rightVar: '10 миллиардов лет',
		},
		CH7: {
			question: 'Где в космосе наблюдается большое содержание спирта?',
			var1: 'На Орионе',
			var2: 'На Луне',
			var3: 'На Нептуне',
			var4: 'В газовом облаке созвездия Орла. Там много этанола',
			rightVar: 'В газовом облаке созвездия Орла. Там много этанола',
		},
		CH8: {
			question: 'Как давно существует на Земле кислород в виде газа?',
			var1: 'Около 3,5 миллиарда лет',
			var2: 'Почти миллиард лет',
			var3: '1,5 миллиарда лет',
			var4: '2 миллиарда лет',
			rightVar: 'Около 3,5 миллиарда лет',
		},
	},
	physics: {
		PH1: {
			question: 'Солнце все обычно рисуют желтым, потому что мы его таким видим, а на самом деле оно',
			var1: 'Красное',
			var2: 'Голубое',
			var3: 'Белое',
			var4: 'Оранжевое',
			rightVar: 'Белое',
		},
		PH2: {
			question: 'Они делятся на три вида: каменные, железные и железно-каменные. О чем идет речь?',
			var1: 'О Метеоритах',
			var2: 'О Звездах',
			var3: 'Об Астероидах',
			var4: 'о Метеорах',
			rightVar: 'О Метеоритах',
		},
		PH3: {
			question: 'На планетах могут идти самые разные дожди.  А какого вида осадков нет во Вселенной?',
			var1: 'Из свинца',
			var2: 'Из драгоценных камней',
			var3: 'Из алюминия',
			var4: 'Из стекла',
			rightVar: 'Из алюминия',
		},
		PH4: {
			question: 'Самым ярким объектом во Вселенной является',
			var1: 'Солнце',
			var2: 'Пульсар',
			var3: 'Квазар',
			var4: 'Сириус',
			rightVar: 'Квазар',
		},
		PH5: {
			question: 'По причине расширения Вселенной электромагнитное излучение',
			var1: 'Становится сильнее',
			var2: 'Удлиняется',
			var3: 'Расширяется',
			var4: 'Становится слабее',
			rightVar: 'Удлиняется',
		},
		PH6: {
			question:
				'Сила притяжения черной дыры настолько велика, что притягивает даже свет, поэтому вокруг нее космическое пространство',
			var1: 'Становится невидимым',
			var2: 'Искажается',
			var3: 'Становится ярче',
			var4: 'Тускнеет',
			rightVar: 'Искажается',
		},
		PH7: {
			question:
				'В чистой воде рыба видна чуть в стороне от ее реального положения. Точно так же мы видим звезды на небе. Что является причиной?',
			var1: 'Строение хрусталика глаза',
			var2: 'Форма глазного дна',
			var3: 'Абберация света',
			var4: 'Слабый глазомер',
			rightVar: 'Абберация света',
		},
		PH8: {
			question:
				'Без морей разница между погодой в освещенной Солнцем области и противоположной ей была бы огромной. К чему бы это привело?',
			var1: 'К концу света',
			var2: 'К вечной мерзлоте',
			var3: 'К бушующим ураганам',
			var4: 'К засухе',
			rightVar: 'К бушующим ураганам',
		},
	},
	investments: {},
	finances: {},
	economics: {},
	arcade: {},
	finish: {},
	biology: {},
	secret: {},
};

export const questions2: Record<Topic, Question> = {
	physics: {
		PH1: {
			question:
				'В&nbsp;мультфильме &laquo;Путешествие на&nbsp;Луну&raquo; героиня собирает ракету и&nbsp;отправляется в&nbsp;космос. Какой скорости должна была достичь ракета, чтобы преодолеть притяжение и&nbsp;уйти с&nbsp;орбиты?',
			var1: '7,9 км/с',
			var2: '10 км/с',
			var3: '11,2 км/с',
			var4: '16,6 км/с',
			rightVar: '11,2 км/с',
		},
		PH2: {
			question:
				'Главное оружие джедаев и&nbsp;ситхов&nbsp;&mdash; световой меч из&nbsp;плазменного лезвия. А&nbsp;для чего плазма может пригодитья в&nbsp;реальном мире?',
			var1: 'Для создания световых мечей',
			var2: 'Для плазменной обработки',
			var3: 'Для изготовления новой версии iPhone',
			var4: 'Для сжигания алмазов',
			rightVar: 'Для плазменной обработки',
		},
		PH3: {
			question:
				'В&nbsp;романе &laquo;Таинственный остров&raquo; главные герои осуществили путешествие на&nbsp;воздушном шаре. За&nbsp;счет какой силы воздушный шар поднимается в&nbsp;воздух?',
			var1: 'Силы Архимеда',
			var2: 'Силы Ампера',
			var3: 'Силы Кулона',
			var4: 'Силы тяжести',
			rightVar: 'Силы Архимеда',
		},
		PH4: {
			question: 'Алмаз&nbsp;&mdash; это очень плотный аллотроп углерода, и&nbsp;он&nbsp;может пригодиться',
			var1: 'В самолетостроении',
			var2: 'В оптике и электронике',
			var3: 'В фотоприемниках',
			var4: 'Все варианты подходят',
			rightVar: 'Все варианты подходят',
		},
		PH5: {
			question:
				'Человек-паук скачет по&nbsp;Нью-Йорку, цепляясь своей паутиной за&nbsp;небоскребы. Чем с&nbsp;точки зрения физики обусловлена его суперспособность?',
			var1: 'Диффузия',
			var2: 'Адгезия',
			var3: 'Когезия',
			var4: 'Суперклей',
			rightVar: 'Адгезия',
		},
		PH6: {
			question:
				'&laquo;Никто его не&nbsp;видывал, а&nbsp;слышать&nbsp;&mdash; всякий слыхивал, без тела, а&nbsp;живет оно, без языка&nbsp;&mdash; кричит&raquo;. О&nbsp;каком явлении повествует Некрасов?',
			var1: 'Инерция',
			var2: 'Диффузия',
			var3: 'Эхо',
			var4: 'Гром',
			rightVar: 'Эхо',
		},
		PH7: {
			question:
				'Про какой белый пар идет речь в&nbsp;стихотворении И. Никитина &laquo;Утро&raquo;: &laquo;Звезды меркнут и&nbsp;гаснут. В&nbsp;огне облака. Белый пар по&nbsp;лугам расстилается&hellip;&raquo;?',
			var1: 'Роса',
			var2: 'Дым',
			var3: 'Туман',
			var4: 'Иней',
			rightVar: 'Туман',
		},
		PH8: {
			question:
				'В&nbsp;известном мультфильме малыш плывет на&nbsp;льдине в&nbsp;поисках мамы. К&nbsp;счастью, лед никогда не&nbsp;тонет в&nbsp;воде. Чем это можно объяснить?',
			var1: 'Вода в жидком состоянии не имеет формы',
			var2: 'Разницей плотности воды и льда',
			var3: 'Разницей температур льда и воды',
			var4: 'Все варианты верны',
			rightVar: 'Разницей плотности воды и льда',
		},
		PH9: {
			question: 'Какова величина скорости света в&nbsp;вакууме (округленное значение)?',
			var1: '200 000 км/с',
			var2: '300 000 м/с',
			var3: '300 000 км/с',
			var4: '150 000 м/с',
			rightVar: '300 000 км/с',
		},
		PH10: {
			question:
				'Если&nbsp;бы не&nbsp;это явление, мы&nbsp;никогда не&nbsp;увидели&nbsp;бы радугу. О&nbsp;каком явлении идет речь?',
			var1: 'Дисперсия',
			var2: 'Энтропия',
			var3: 'Диффузия',
			var4: 'Кристаллизация',
			rightVar: 'Дисперсия',
		},
		PH11: {
			question: 'Что не&nbsp;является процессом диффузии?',
			var1: 'Замешивание теста',
			var2: 'Засолка овощей',
			var3: 'Приготовление салата',
			var4: 'Заваривание чая',
			rightVar: 'Приготовление салата',
		},
		PH12: {
			question:
				'Если расплавить ртуть и&nbsp;опустить ее&nbsp;в&nbsp;сосуд с&nbsp;водой, то&nbsp;какие изменения произойдут с&nbsp;температурой воды?',
			var1: 'Вода начнет нагреваться',
			var2: 'Вода закипит',
			var3: 'Вода замерзнет',
			var4: 'Никаких изменений не произойдет',
			rightVar: 'Вода замерзнет',
		},
		PH13: {
			question:
				'В&nbsp;сериале &laquo;Теория большого взрыва&raquo; доктор Купер создал теорию об&nbsp;идеальной суперасимметрии. А&nbsp;что такое симметрия?',
			var1: 'Соразмерное расположение частей',
			var2: 'Изменяющееся исходное состояние',
			var3: 'Правильные оба',
			var4: 'Неправильные оба',
			rightVar: 'Соразмерное расположение частей',
		},
		PH14: {
			question:
				'&laquo;Посади ты&nbsp;эту птицу,&nbsp;&mdash; Молвил он&nbsp;царю,&nbsp;&mdash; на&nbsp;спицу; Петушок мой золотой Будет верный сторож твой&raquo;. Функции какого прибора исполнял Золотой петушок?',
			var1: 'Гидрофона',
			var2: 'Радиопередатчика',
			var3: 'Летающего дрона',
			var4: 'Радара',
			rightVar: 'Радара',
		},
		PH15: {
			question:
				'Сколько в&nbsp;мире цветов? Не&nbsp;счесть. Хотя это зависит от&nbsp;того, кто смотрит на&nbsp;этот мир. Кто видит и&nbsp;различает инфракрасные лучи?',
			var1: 'Собака',
			var2: 'Кошка',
			var3: 'Комар',
			var4: 'Лошадь',
			rightVar: 'Комар',
		},
	},
	chemistry: {
		CH1: {
			question:
				'Кольцо в&nbsp;кинотрилогии &laquo;Властелин Колец&raquo; было создано из&nbsp;золота. Какая температура нужна, чтобы расплавить это кольцо?',
			var1: '1064 °C',
			var2: '5000 °C',
			var3: '150 °C',
			var4: '3333 °C',
			rightVar: '1064 °C',
		},
		CH2: {
			question: 'Это изделие представляет собой метаматериал с&nbsp;отрицательным показателем преломления.',
			var1: 'Мантия-невидимка',
			var2: 'Зеркало Еиналеж',
			var3: 'Карта мародеров',
			var4: 'Волшебная палочка',
			rightVar: 'Мантия-невидимка',
		},
		CH3: {
			question:
				'У&nbsp;Саши Черного есть такие строки: &laquo;Лопнет в&nbsp;градуснике ртуть, Или лопнут скулы. Тяжелей и&nbsp;гуще муть, Холод злей акулы&raquo;. О&nbsp;какой температуре окружающей среды идет речь?',
			var1: '–20 ᵒС и выше',
			var2: '–25 ᵒС и выше',
			var3: '–29 ᵒС',
			var4: '–38 ᵒС и ниже',
			rightVar: '–38 ᵒС и ниже',
		},
		CH4: {
			question:
				'Представьте, что вам срочно нужно вскипятить воду и&nbsp;у&nbsp;вас есть 4 кастрюли из&nbsp;разных металлов. В&nbsp;какой из&nbsp;них вода закипит быстрее?',
			var1: 'Алюминий',
			var2: 'Медь',
			var3: 'Железо',
			var4: 'Латунь',
			rightVar: 'Медь',
		},
		CH5: {
			question:
				'Эльза из&nbsp;мультфильма &laquo;Холодное сердце&raquo; с&nbsp;помощью магии возвела ледяной замок. А&nbsp;при какой температуре по&nbsp;шкале Фаренгейта замерзает вода?',
			var1: '48 °F',
			var2: '–1 °F',
			var3: '32 °F',
			var4: '0 °C',
			rightVar: '32 °F',
		},
	},
	history: {
		H1: {
			question:
				'Первый в&nbsp;мире электронный музыкальный инструмент создан физиком и&nbsp;музыкантом Львом Терменом в&nbsp;1918 году в&nbsp;Петрограде. Как он&nbsp;называется?',
			var1: 'Термапак',
			var2: 'Терменвокс',
			var3: 'Термен',
			var4: 'Волнотек',
			rightVar: 'Терменвокс',
		},
		H2: {
			question:
				'Это страшное орудие убийства с&nbsp;точки зрения физики представляет собой рычаг с&nbsp;массой, а&nbsp;его движение принято считать вращательным.',
			var1: 'Нож',
			var2: 'Гильотина',
			var3: 'Топор',
			var4: 'Копьё',
			rightVar: 'Топор',
		},
		H3: {
			question:
				'В&nbsp;темном-темном городе, в&nbsp;темной-темной комнате есть один маленький источник света. Родоначальником чего стала &laquo;темная комната&raquo;?',
			var1: 'Рентген',
			var2: 'Принтер',
			var3: 'Факс',
			var4: 'Фотоаппарат',
			rightVar: 'Фотоаппарат',
		},
		H4: {
			question:
				'Какой ученый был удостоен Нобелевской премии 1921 года за&nbsp;работы по&nbsp;теоретической физике, но&nbsp;без учета его самых известных теорий?',
			var1: 'Томас Эдисон',
			var2: 'Альберт Эйнштейн',
			var3: 'Лоренц Хендрик',
			var4: 'Роберт Гук',
			rightVar: 'Альберт Эйнштейн',
		},
		H5: {
			question:
				'Все мы&nbsp;знаем Чебурашку&nbsp;&mdash; персонажа, придуманного Эдуардом Успенским. А&nbsp;для чего Чебурашке могут быть нужны такие большие уши?',
			var1: 'Чтобы лучше слышать',
			var2: 'Для приёма радиочастот от 30 МГц до 3 ГГц',
			var3: 'Для теплорегуляции',
			var4: 'Для защиты от хищников',
			rightVar: 'Для теплорегуляции',
		},
		H6: {
			question:
				'Барон Мюнхгаузен&nbsp;&mdash; известный враль. А&nbsp;может&nbsp;ли хоть что-то из&nbsp;приведенного ниже списка оказаться правдой?',
			var1: 'Лететь на пушечном ядре',
			var2: 'Пересесть с одного пушечного ядра на другое',
			var3: 'Лететь самому как пушечное ядро',
			var4: 'Вытащить себя за волосы',
			rightVar: 'Лететь самому как пушечное ядро',
		},
		H7: {
			question:
				'С&nbsp;каким прибором можно сравнить волшебное зеркальце, которое имелось у&nbsp;царицы в&nbsp;&laquo;Сказке о&nbsp;мертвой царевне и&nbsp;семи богатырях?&raquo;',
			var1: 'С медицинским сканером',
			var2: 'Со смартфоном',
			var3: 'С фото- и видеокамерой',
			var4: 'С обычным зеркалом с подсветкой',
			rightVar: 'Со смартфоном',
		},
		H8: {
			question:
				'В&nbsp;сказке о&nbsp;царе Салтане царицу с&nbsp;сыном посадили в&nbsp;бочку и&nbsp;пустили в&nbsp;море. Почему они не&nbsp;утонули?',
			var1: 'Бочка была большая',
			var2: 'Все щели в бочке были просмолены',
			var3: 'Бочка была деревянная',
			var4: 'Бочку сделали из железа',
			rightVar: 'Все щели в бочке были просмолены',
		},
		H9: {
			question:
				'Знаменитый мысленный эксперимент австрийского ученого в&nbsp;1935&nbsp;г.&nbsp;именно с&nbsp;этим животным показал изъяны в&nbsp;трактовке квантовой механики.',
			var1: 'Котом',
			var2: 'Волком',
			var3: 'Собакой',
			var4: 'Тушканчиком',
			rightVar: 'Котом',
		},
		H10: {
			question:
				'В&nbsp;повести К. Дойля &laquo;Собака Баскервилей&raquo; знаменитый сыщик обезвредил светящегося монстра. В&nbsp;чем был секрет его свечения?',
			var1: 'Флуоресцентная краска',
			var2: 'Люминесцентная краска',
			var3: 'Светоотражающая краска',
			var4: 'Биолюминесценция',
			rightVar: 'Люминесцентная краска',
		},
		H11: {
			question:
				'В&nbsp;романе &laquo;Мастер и&nbsp;Маргарита&raquo; Аннушка разлила на&nbsp;трамвайных путях масло. Каким физическим явлением можно описать причину смерти Берлиоза?',
			var1: 'Увеличением силы реакции опоры',
			var2: 'Уменьшением силы упругости',
			var3: 'Уменьшением силы трения',
			var4: 'Возникновением силы Лоренца',
			rightVar: 'Уменьшением силы трения',
		},
		H12: {
			question:
				'В&nbsp;фильме &laquo;Душа&raquo; у&nbsp;души 22 есть целая плеяда учителей. Среди них А.&nbsp;Эйнштейн, М.&nbsp;Кюри, М.&nbsp;Али. Чем&nbsp;же прославился Альберт Эйнштейн?',
			var1: 'Придумал теорию относительности',
			var2: 'Определил число Авогадро',
			var3: 'Верны оба варианта',
			var4: 'Ни один из вариантов не является верным',
			rightVar: 'Верны оба варианта',
		},
	},
	investments: {},
	finances: {},
	economics: {},
	astronomy: {},
	biology: {},
	arcade: {},
	finish: {},
	secret: {},
};

export const questions3: Record<Topic, Question> = {
	investments: {
		I1: {
			question: 'Какие факторы могут вызвать резкую продажу акций инвесторами?',
			var1: 'Санкции против компании',
			var2: 'Упадок бизнеса',
			var3: 'Крупная авария на объекте',
			var4: 'Все вышеперечисленное ',
			rightVar: 'Все вышеперечисленное ',
		},
		I2: {
			question: 'Вы купили акцию за 228 рублей, потом ее цена выросла до 350 рублей. Продав акцию, вы заработаете',
			var1: '142 рубля',
			var2: '122 рубля ',
			var3: '112 рублей ',
			var4: '132 рубля ',
			rightVar: '122 рубля ',
		},
		I3: {
			question: 'На основе котировок какого эталонного сорта нефти определяется цена на российскую нефть?',
			var1: 'WTI ',
			var2: 'Dubai Crude ',
			var3: 'Urals',
			var4: 'Brent ',
			rightVar: 'Brent ',
		},
		I4: {
			question: 'Какой инструмент позволяет компаниям взять в долг?',
			var1: 'Фонды',
			var2: 'Фьючерсы ',
			var3: 'Опционы ',
			var4: 'Облигации',
			rightVar: 'Облигации',
		},
		I5: {
			question: 'Какая компания обладает крупнейшими запасами природного газа в мире? ',
			var1: 'BP',
			var2: 'Total',
			var3: 'Газпром',
			var4: 'PGNiG',
			rightVar: 'Газпром',
		},
		I6: {
			question: 'У какой российской компании Boeing и Airbus закупают титан? ',
			var1: 'Северсталь',
			var2: 'НЛМК',
			var3: 'ВСМПО-АВИСМА',
			var4: 'ТМК',
			rightVar: 'ВСМПО-АВИСМА',
		},
		I7: {
			question: 'Какая компания обладает крупнейшими запасами золота в мире? ',
			var1: 'Newmont Mining',
			var2: 'Бурятзолото ',
			var3: 'Barrick Gold ',
			var4: 'Полюс ',
			rightVar: 'Полюс ',
		},
		I8: {
			question: 'Как называется инвестиционный холдинг Уоррена Баффета? ',
			var1: 'AIG',
			var2: 'Vanguard ',
			var3: 'Berkshire Hathaway',
			var4: 'WB Corporation ',
			rightVar: 'Berkshire Hathaway',
		},
		I9: {
			question: 'Какой металл занимает наибольшую долю в выручке Норникеля?',
			var1: 'Никель',
			var2: 'Медь ',
			var3: 'Палладий',
			var4: 'Платина',
			rightVar: 'Палладий',
		},
		I10: {
			question: 'Крупнейшая публичная компания России без государственного участия — это',
			var1: 'Лукойл',
			var2: 'Газпром',
			var3: 'Роснефть ',
			var4: 'Сбербанк',
			rightVar: 'Лукойл',
		},
		I11: {
			question: 'Как называются рискованные облигации с кредитным рейтингом ниже инвестиционного уровня? ',
			var1: 'Уличные',
			var2: 'Мусорные',
			var3: 'Грошовые',
			var4: 'Карманные',
			rightVar: 'Мусорные',
		},
	},
	economics: {
		E1: {
			question: 'Банк взимает ежемесячную плату за обслуживание карт. К какому типу такая плата относится?',
			var1: 'Комиссионные доходы',
			var2: 'Процентные доходы',
			var3: 'Кредитные расходы',
			var4: 'Прочие доходы',
			rightVar: 'Комиссионные доходы',
		},
		E2: {
			question: 'Какой индекс существует на самом деле? ',
			var1: 'Индекс бигмака',
			var2: 'Индекс солений ',
			var3: 'Индекс Пхеньянской биржи',
			var4: 'Индекс всех индексов ',
			rightVar: 'Индекс бигмака',
		},
		E3: {
			question: 'Какой показатель отражает экономическую мощь государства? ',
			var1: 'ВНП',
			var2: 'ВНД',
			var3: 'ВВП',
			var4: 'ЧНП',
			rightVar: 'ВВП',
		},
		E4: {
			question: 'Вы закупились в магазине на 1000 ₽. Спустя год тот же набор продуктов стоит 900 ₽. Это называется',
			var1: 'Инфляцией ',
			var2: 'Стагфляцией ',
			var3: 'Коррупцией ',
			var4: 'Дефляцией ',
			rightVar: 'Дефляцией ',
		},
		E5: {
			question:
				'На рынке существует множество производителей товара, однако присутствует лишь один покупатель. Такая ситуация зовется',
			var1: 'Олигополией',
			var2: 'Монополией ',
			var3: 'Симфонией ',
			var4: 'Монопсонией ',
			rightVar: 'Монопсонией ',
		},
		E6: {
			question:
				'Во время пузыря доткомов компании добавляли к своему названию окончание, после чего акции этих компаний в среднем росли на 74% за 10 дней. Что это за окончание? ',
			var1: '.ru',
			var2: '.http ',
			var3: '.com',
			var4: '.su',
			rightVar: '.com',
		},
		E7: {
			question: 'Экономика какой страны является крупнейшей в мире по размеру ВВП? ',
			var1: 'США',
			var2: 'Япония ',
			var3: 'КНР',
			var4: 'Индия',
			rightVar: 'США',
		},
		E8: {
			question: 'Сколько граммов золота содержится в тройской унции? ',
			var1: '28,2',
			var2: '13',
			var3: '31,1',
			var4: '36,6',
			rightVar: '31,1',
		},
		E9: {
			question: 'Через какую артерию проходит основной объем торговли между КНР и ЕС?',
			var1: 'Панамский канал',
			var2: 'Суэцкий канал ',
			var3: 'Кильский канал',
			var4: 'Великий канал',
			rightVar: 'Суэцкий канал ',
		},
		E10: {
			question: 'Какая страна обладает крупнейшими запасами нефти в мире? ',
			var1: 'США ',
			var2: 'Венесуэла',
			var3: 'Саудовская Аравия',
			var4: 'Россия',
			rightVar: 'Венесуэла',
		},
	},
	finances: {
		F1: {
			question:
				'Ситуация, когда в экономике наблюдается высокая инфляция и безработица, а экономический рост близок к нулю, называется',
			var1: 'Инфляция',
			var2: 'Стагфляция ',
			var3: 'Стагнация ',
			var4: 'Дисперсия ',
			rightVar: 'Стагфляция ',
		},
		F2: {
			question: 'Какие оборотные активы наименее ликвидны?',
			var1: 'Краткосрочные инвестиции',
			var2: 'Денежные средства',
			var3: 'Эквиваленты денежных средств',
			var4: 'Запасы на складе',
			rightVar: 'Запасы на складе',
		},
		F3: {
			question: 'Компания продала товаров на $1 млрд. Этот показатель зовется',
			var1: 'Выручкой ',
			var2: 'Прибылью ',
			var3: 'Маржинальностью ',
			var4: 'Успехом бизнеса ',
			rightVar: 'Выручкой ',
		},
		F4: {
			question: 'При росте ключевой ставки цена облигаций',
			var1: 'Падает',
			var2: 'Растет ',
			var3: 'Остается неизменной',
			var4: 'Колеблется ',
			rightVar: 'Падает',
		},
		F5: {
			question: 'Как называется валюта Монголии? ',
			var1: 'Сом ',
			var2: 'Тенге ',
			var3: 'Тугрик',
			var4: 'Улус',
			rightVar: 'Тугрик',
		},
		F6: {
			question: 'Какой из этих финансовых показателей не может быть отрицательным?',
			var1: 'Прибыль ',
			var2: 'Выручка ',
			var3: 'Чистый долг ',
			var4: 'Прибыль на акцию ',
			rightVar: 'Выручка ',
		},
		F7: {
			question:
				'Компания решает выплатить дивиденды в размере $1 млн. Всего у компании 1000 акций. Сколько денег получит инвестор, который владеет тремя акциями? Налоги можно не учитывать.',
			var1: '$100',
			var2: '$1000',
			var3: '$3000',
			var4: '$300',
			rightVar: '$3000',
		},
		F8: {
			question: 'Какой известный ученый потерял почти все свои деньги после вложений в биржевой пузырь? ',
			var1: 'Исаак Ньютон ',
			var2: 'Нильс Бор ',
			var3: 'Ричард Фейнман ',
			var4: 'Альберт Эйнштейн',
			rightVar: 'Исаак Ньютон ',
		},
		F9: {
			question: 'На логотипе какой криптовалюты изображена собака породы сиба-ину? ',
			var1: 'Ethereum',
			var2: 'Dogecoin ',
			var3: 'Bitcoin ',
			var4: 'Ripple',
			rightVar: 'Dogecoin ',
		},
		F10: {
			question: 'Кто придумал золотой стандарт? ',
			var1: 'Альберт Эйнштейн ',
			var2: 'Исаак Ньютон',
			var3: 'Геродот ',
			var4: 'Михаил Ломоносов ',
			rightVar: 'Исаак Ньютон',
		},
		F11: {
			question: 'Где находится старейший банк в мире, работающий до сих пор? ',
			var1: 'Лондон, Великобритания',
			var2: 'Сиена, Италия',
			var3: 'Стокгольм, Швеция ',
			var4: 'Франкфурт, Германия ',
			rightVar: 'Сиена, Италия',
		},
	},
	astronomy: {},
	biology: {},
	arcade: {},
	finish: {},
	secret: {},
	physics: {},
	chemistry: {},
	history: {},
};
