import { CSSProperties, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { AppContext } from '../storeContext';

type StrokeLinecap = 'round' | 'butt' | undefined;

interface Props {
	startTime: number;
	defaultIsShowDescription: boolean;
	winCond: boolean;
	name: string;
	desc: string;
	children: ReactNode;
	setIsShowArcade: Dispatch<SetStateAction<boolean>>;
	contentStyle?: CSSProperties;
}

export const ArcadeBase = ({
	startTime,
	defaultIsShowDescription,
	setIsShowArcade,
	winCond,
	name,
	desc,
	children,
	contentStyle,
}: Props) => {
	const [timeLeft, setTimeLeft] = useState<number>(startTime);
	const [isShowGameDesc, setIsShowGameDesc] = useState<boolean>(defaultIsShowDescription);
	const [isShowExitWarning, setIsShowExitWarning] = useState<boolean>(false);
	const { currentPosition, theme, isQuestPhysics, isQuestCosmic, isQuestTinkoff, setResults } = useContext(AppContext);

	const forwardIcon = (
		<svg width="100%" height="100%" viewBox="0 0 99 99" fill="none" xmlns="http://www.w3.org/2000/svg">
			<circle cx="49.5" cy="49.5" r="49.5" fill={theme.themeColor} />
			<path
				d="M19.8 46.9107V59.043H53.406L48.9289 75.4683L79.2 49.7664L48.9289 21.7799L53.406 42.0466L19.8 46.9107Z"
				fill="white"
			/>
		</svg>
	);

	const exitBtn = (
		<button
			className="exitBtn"
			onClick={() => setIsShowExitWarning(true)}
			style={{
				position: 'absolute',
				top: '6%',
				right: '5%',
				width: '8%',
			}}
		>
			<svg width="100%" height="100%" viewBox="0 0 72 48" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M71.6161 36.5071L65.687 41.5811L36.8525 27.7862L3.29902 47.1583L0.841264 36.3403L27.4969 23.3102L6.0238 13.0371L8.38395 -4.2255e-06L39.0103 17.6821L63.6395 5.64268L66.5312 10.6512L46.6818 22.1112L71.6161 36.5071Z"
					fill={theme.themeColor}
				/>
			</svg>
		</button>
	);

	const windowRect = (
		<svg width="100%" height="100%" viewBox="0 0 817 464" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10.8026 37.4206L5.77582 37.6192L6.0052 42.6447L24.7459 453.228L24.9653 458.034L29.7764 458L788.775 452.583L793.546 452.549L793.735 447.781L810.996 11.1975L811.21 5.79021L805.803 6.0039L10.8026 37.4206Z"
				fill="#001B3A"
				stroke={theme.themeColor}
				strokeWidth="10"
			/>
		</svg>
	);

	const progressBarStyles = {
		text: { fill: 'white', fontSize: '2.8rem' },
		trail: { stroke: 'transparent' },
		path: { stroke: theme.themeColorLight, strokeLinecap: 'round' as StrokeLinecap },
	};

	const result = timeLeft === 0 ? 'fail' : winCond ? 'success' : null;

	useEffect(() => {
		if (result) {
			setResults((res) => ({
				...res,
				[currentPosition]: result,
			}));
		}
	}, [result, setResults, currentPosition]);

	useEffect(() => {
		let timer: string | number | NodeJS.Timeout | undefined;
		if (!isShowGameDesc) {
			if (!timer) {
				timer = setInterval(() => {
					setTimeLeft((v) => v - 1);
				}, 1000);
			}
			if (timeLeft === 0 || isShowExitWarning) {
				clearInterval(timer);
			}
		}
		return () => clearInterval(timer);
	}, [timeLeft, isShowGameDesc, isShowExitWarning]);

	if (isShowGameDesc) {
		return (
			<div
				className="arcade"
				style={{
					display: 'flex',
					justifyContent: 'center',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<div
					className="description"
					style={{
						color: 'white',
						width: '75%',
						margin: 'auto',
						fontFamily: 'Gilroy',
					}}
				>
					<h3
						className="heading"
						style={{
							fontFamily: 'Gilroy-Extrabold',
							display: 'block',
							color: theme.themeColorLight,
							fontSize: '2.4rem',
							lineHeight: 1,
							marginTop: '20%',
							marginBottom: '25px	',
						}}
					>
						{name}
					</h3>
					{desc}
					<button onClick={() => setIsShowGameDesc(false)}>
						{theme.start.btnBg}
						<p>ИГРАТЬ</p>
					</button>
				</div>
			</div>
		);
	}
	return (
		<div
			className="arcade"
			style={{
				position: 'relative',
				paddingBottom: '100%',
				background: `${theme.themeBgColor}`,
			}}
		>
			<div
				className="progressBarContainer"
				style={{
					left: '6%',
					position: 'absolute',
					top: '4%',
					// right: '7%',
					width: '10.4%',
					height: 'auto',
					fontFamily: 'Gilroy-Bold',
				}}
			>
				{!result ? (
					<CircularProgressbar
						minValue={0}
						maxValue={startTime}
						value={timeLeft}
						text={`${timeLeft}`}
						strokeWidth={4}
						styles={progressBarStyles}
						className="timer"
					/>
				) : (
					<button
						onClick={() => setIsShowArcade(false)}
						style={{ padding: 0, margin: 0, background: 'none', border: 'none', cursor: 'pointer' }}
					>
						{forwardIcon}
					</button>
				)}
			</div>
			<div className="content" style={{ ...contentStyle, ...(result ? { pointerEvents: 'none' } : {}) }}>
				{children}
			</div>
			{!result && exitBtn}
			{isShowExitWarning && (
				<div
					style={{
						position: 'absolute',
						fontFamily: 'Gilroy',
						color: 'white',
						zIndex: 99,
						width: '81.62%',
						// margin: 'auto',
						transform: 'translate(12%, 50%)',
					}}
				>
					{windowRect}
					<div
						className="exitContent"
						style={{
							position: 'absolute',
							top: '50%',
							transform: 'translateY(-50%)',
							fontFamily: 'Gilroy',
							padding: '10%',
						}}
					>
						<h1 style={{ color: theme.themeColor, fontSize: '2rem' }}>ВЫЙТИ ИЗ ИГРЫ?</h1>
						<p style={{ fontSize: '1.67rem' }}>
							{`Если ты выйдешь из игры, то автоматически проиграешь и лишишь себя возможности заработать дополнительн${
								isQuestTinkoff ? 'ую' : 'ый'
							}
							${isQuestCosmic ? 'кристалл' : isQuestPhysics ? 'протон' : 'монету'}.`}
						</p>
						<div
							className="btnGroup"
							style={{ display: 'flex', flexWrap: 'nowrap', margin: '5% auto', justifyContent: 'center' }}
						>
							<button onClick={() => setIsShowExitWarning(false)}>
								{theme.quitArcadeBtnNo}
								<p style={{ textAlign: 'center', margin: '5% 0 0 0', zIndex: 3 }}>НЕТ</p>
							</button>
							<button
								onClick={() => {
									setIsShowExitWarning(false);
									setIsShowArcade(false);
									setResults((res) => ({
										...res,
										[currentPosition]: 'fail',
									}));
								}}
							>
								{theme.quitArcadeBtnYes}
								<p style={{ textAlign: 'center', margin: '-5% 0 0 0', zIndex: 3 }}>ДА</p>
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
