import { MutableRefObject, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { CardMemory } from './CardMemory';
import flask from './../assets/flask.svg';
import brain from './../assets/brain.svg';
import experiment from './../assets/experiment.svg';
import hourglass from './../assets/hourglass.svg';
import rocket from './../assets/rocket.svg';
import atom from './../assets/atom.svg';
import { AppContext } from '../storeContext';
import { ArcadeBase } from './ArcadeBase';
import casecard from './../assets/card_case.svg';
import ruble from './../assets/card_ruble.svg';
import ciks from './../assets/card_cc.svg';
import bank from './../assets/card_bank.svg';
import graph from './../assets/card_aroow.svg';
import gold from './../assets/card_gold.svg';

export type CardIcon = {
	type: string;
	img: string;
};

const uniqueCardsArray = [
	{
		type: 'flask',
		img: flask,
	},
	{
		type: 'brain',
		img: brain,
	},
	{
		type: 'experiment',
		img: experiment,
	},
	{
		type: 'hourglass',
		img: hourglass,
	},
	{
		type: 'atom',
		img: atom,
	},
	{
		type: 'rocket',
		img: rocket,
	},
];

const uniqueCardsArrayTinkoff = [
	{
		type: 'casecard',
		img: casecard,
	},
	{ type: 'ruble', img: ruble },
	{ type: 'bank', img: bank },
	{ type: 'ciks', img: ciks },
	{ type: 'graph', img: graph },
	{ type: 'gold', img: gold },
];

function shuffleCards(array: Array<CardIcon>) {
	const length = array.length;
	for (let i = length; i > 0; i--) {
		const randomIndex = Math.floor(Math.random() * i);
		const currentIndex = i - 1;
		const temp = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temp;
	}
	return array;
}

export const Memory = () => {
	const { setIsShowArcade1, theme, isQuestTinkoff } = useContext(AppContext);
	const [cards] = useState(() =>
		shuffleCards(
			isQuestTinkoff
				? uniqueCardsArrayTinkoff.concat(uniqueCardsArrayTinkoff)
				: uniqueCardsArray.concat(uniqueCardsArray)
		)
	);
	const [openCards, setOpenCards] = useState<number[]>([]);
	const [clearedCards, setClearedCards] = useState<Record<CardIcon['type'], boolean>>({});
	const [shouldDisableAllCards, setShouldDisableAllCards] = useState(false);
	const [winCond, setWinCond] = useState<boolean>(false);

	const timeout: MutableRefObject<NodeJS.Timeout | undefined> = useRef();

	const disable = () => {
		setShouldDisableAllCards(true);
	};
	const enable = () => {
		setShouldDisableAllCards(false);
	};

	const checkCompletion = useCallback(() => {
		if (Object.keys(clearedCards).length === (isQuestTinkoff ? uniqueCardsArrayTinkoff : uniqueCardsArray).length) {
			setWinCond(true);
		}
	}, [clearedCards, isQuestTinkoff]);

	const evaluate = useCallback(() => {
		const [first, second] = openCards;
		enable();
		if (cards[first].type === cards[second].type) {
			setClearedCards((prev) => ({ ...prev, [cards[first].type]: true }));
			setOpenCards([]);
			return;
		}
		// This is to flip the cards back after 500ms duration
		timeout.current = setTimeout(() => {
			setOpenCards([]);
		}, 500);
	}, [cards, openCards]);

	const handleCardClick = (index: number) => {
		if (openCards.length === 1) {
			setOpenCards((prev) => [...prev, index]);
			disable();
		} else {
			clearTimeout(timeout.current);
			setOpenCards([index]);
		}
	};

	useEffect(() => {
		let timeout: NodeJS.Timeout;
		if (openCards.length === 2) {
			timeout = setTimeout(evaluate, 300);
		}
		return () => {
			clearTimeout(timeout);
		};
	}, [openCards, evaluate]);

	useEffect(() => {
		checkCompletion();
	}, [clearedCards, checkCompletion]);

	const checkIsFlipped = (index: number): boolean => {
		return openCards.includes(index);
	};

	const checkIsInactive = (card: CardIcon): boolean => {
		return Boolean(clearedCards[card.type]);
	};

	return (
		<ArcadeBase
			startTime={120}
			defaultIsShowDescription={true}
			setIsShowArcade={setIsShowArcade1}
			name={theme.arcade1.name}
			desc={theme.arcade1.desc}
			winCond={winCond}
		>
			{cards.map((card, index) => {
				return (
					<CardMemory
						key={index}
						card={card}
						index={index}
						isDisabled={shouldDisableAllCards}
						isInactive={checkIsInactive(card)}
						isFlipped={checkIsFlipped(index)}
						onClick={handleCardClick}
					/>
				);
			})}
		</ArcadeBase>
	);
};
