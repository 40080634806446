import { useContext } from 'react';
import { AppContext } from '../../storeContext';
import './Tile.css';

interface Props {
	onClick: () => void;
	coord: number[];
	value: number;
	done: boolean;
}
export const Tile = ({ onClick, coord, value, done }: Props) => {
	const { theme } = useContext(AppContext);
	let style = {
		top: coord[0] * 25 + '%',
		left: coord[1] * 25 + '%',
		backgroundColor: done ? 'orange' : theme.themeColorLight,
		borderColor: theme.themeBgColor,
	};

	let className = ['tile', 'rounded'];
	if (!value) {
		className.push('tile--empty');
	}
	if (done) {
		className.push('tile--done');
	}

	return (
		<div className={className.join(' ')} style={style} onClick={onClick}>
			<span className="tile__inner">{value || ''}</span>
		</div>
	);
};
