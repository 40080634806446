import { CSSProperties, useContext, useRef, useState } from 'react';
import { AppContext } from '../storeContext';

export const StartFinishScreen = (): JSX.Element => {
	const { gameStop, setIsShowStartScreen, points, theme, isQuestCosmic, isQuestTinkoff } = useContext(AppContext);
	const [page, setPage] = useState<number>(1);
	const contentRef = useRef<HTMLDivElement | null>(null);
	const startNavOption = (pageNum: number) => (
		<svg
			onClick={() => setPage(pageNum)}
			style={{ cursor: 'pointer' }}
			key={pageNum}
			width="15"
			height="15"
			viewBox="0 0 15 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="7.5" cy="7.5" r="7.5" fill={pageNum === page ? theme.start.nav.active : theme.start.nav.default} />
		</svg>
	);

	const exitBtn = (
		<button
			className="exitBtn"
			onClick={() => setIsShowStartScreen(false)}
			style={{
				position: 'absolute',
				top: '10%',
				right: isQuestCosmic ? '-9%' : '-4%',
				width: '6%',
			}}
		>
			<svg width="100%" height="100%" viewBox="0 0 72 48" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M71.6161 36.5071L65.687 41.5811L36.8525 27.7862L3.29902 47.1583L0.841264 36.3403L27.4969 23.3102L6.0238 13.0371L8.38395 -4.2255e-06L39.0103 17.6821L63.6395 5.64268L66.5312 10.6512L46.6818 22.1112L71.6161 36.5071Z"
					fill={theme.themeColorLight}
				/>
			</svg>
		</button>
	);

	const onBtnlick = () => {
		if (!gameStop) {
			if (page === 1) {
				setPage(2);
			} else if (page === 2) {
				setPage(3);
			} else {
				setIsShowStartScreen?.(false);
			}
		}
		if (gameStop) {
			// eslint-disable-next-line no-restricted-globals
			location.assign('../konkurs');
		}
	};

	const startStyle: CSSProperties = {
		position: 'relative',
		width: '100%',
		height: '100%',
		background: theme.start.frameStyle,
		fontFamily: 'Gilroy-Extrabold',
		color: 'white',
	};

	const contentStyle: CSSProperties = {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		justifyItems: 'center',
		paddingLeft: '14%',
		marginRight: '14%',
		marginLeft: '5%',
		height: '100%',
	};

	const showText = () => {
		if (gameStop || !points) {
			return (
				<div style={theme.start.textStyle}>{!points ? theme.start.content.lose : theme.start.content.win(points)}</div>
			);
		}
		return theme.start.content[page] ? (
			<div className="innerContent" ref={contentRef} style={theme.start.innerContentStyle}>
				{theme.start.content[page]}
			</div>
		) : null;
	};

	return (
		<div className="start" style={startStyle}>
			<div className="content" style={{ ...contentStyle, ...theme.start.contentStyle }}>
				{!gameStop && exitBtn}
				{!gameStop && theme.start.h4}
				<h1 style={theme.start.h1Style}>{!gameStop ? theme.start.questName : !points ? 'Ой!' : 'Поздравляем!'}</h1>
				{showText()}
				<button
					className="fwdBtn"
					onClick={onBtnlick}
					style={gameStop ? { ...theme.start.btnStyle, width: '22%' } : theme.start.btnStyle}
				>
					{theme.start.btnBg}
					<p style={isQuestTinkoff ? { textAlign: 'center', color: 'black' } : { textAlign: 'center' }}>
						{!gameStop && (page === 1 || page === 2) ? 'ДАЛЕЕ' : page === 3 ? 'ПОЕХАЛИ' : 'К КОНКУРСУ'}
					</p>
				</button>
				{!gameStop && (
					<div style={{ display: 'flex', width: '75px', justifyContent: 'space-between', height: '15px' }}>
						{[1, 2, 3].map((_, i) => startNavOption(i + 1))}
					</div>
				)}
			</div>
		</div>
	);
};
