import { CSSProperties, useContext } from 'react';
import { ICard, mapBackgroundShift } from '../helpers';
import gridMap from './../assets/grid.png';
import { Card } from './Card';
import { Arrow } from './Arrow';
import { AppContext } from '../storeContext';

export const Map = (): JSX.Element => {
	const { results, currentPosition, setCurrentPosition, setIsShowQuiz, isQuestCosmic, theme } = useContext(AppContext);

	const getCardPosition = (currPos: string, cardOnMapPos: string): number => {
		const vShift: number = +cardOnMapPos.slice(0, 1) - +currPos.slice(0, 1);
		const hShift: number = +cardOnMapPos.slice(-1) - +currPos.slice(-1);
		const isOnMapShifts = [-1, 0, 1];

		if (isOnMapShifts.includes(vShift) && isOnMapShifts.includes(hShift)) {
			if (hShift === -1 && vShift === -1) {
				return 1;
			}
			if (hShift === 0 && vShift === -1) {
				return 2;
			}
			if (hShift === 1 && vShift === -1) {
				return 3;
			}
			if (hShift === -1 && vShift === 0) {
				return 4;
			}
			if (hShift === 0 && vShift === 0) {
				return 5;
			}
			if (hShift === 1 && vShift === 0) {
				return 6;
			}
			if (hShift === -1 && vShift === 1) {
				return 7;
			}
			if (hShift === 0 && vShift === 1) {
				return 8;
			}
			if (hShift === 1 && vShift === 1) {
				return 9;
			}
		}
		return 0;
	};

	const personaStyle: CSSProperties = {
		position: 'absolute',
		width: '18%',
		top: '38%',
		left: '41%',
		...theme.map.personaPosStyle,
	};

	const backgroundImage = isQuestCosmic ? `url(${theme.map.img})` : `url(${gridMap}), url(${theme.map.img})`;
	const backgroundPosition = mapBackgroundShift[currentPosition].join(' ');

	return (
		<div
			className="map"
			style={{
				backgroundImage,
				backgroundPosition,
				backgroundSize: 'calc((8/3) * 100%)',
				paddingBottom: '100%',
			}}
		>
			{theme.cards.map(({ id, position, topic }: ICard) => {
				const cardPos = getCardPosition(currentPosition, position);
				return cardPos ? (
					<Card
						key={id}
						result={results[position]}
						currentPosition={currentPosition}
						setCurrentPosition={setCurrentPosition}
						topic={topic}
						cardPosition={cardPos}
						setIsShowQuiz={setIsShowQuiz}
					/>
				) : null;
			})}

			<img src={theme.map.persona} alt="astronaut" style={personaStyle} />
			{!currentPosition.includes('.6') && <Arrow nav="right" />}
			{!currentPosition.includes('.1') && !currentPosition.includes('1.2') && <Arrow nav="left" />}
			{!currentPosition.includes('1.') && <Arrow nav="up" />}
			{!currentPosition.includes('6.') && <Arrow nav="down" />}
		</div>
	);
};
